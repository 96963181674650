const afterDecimalFourDigit = (num) => parseFloat(num).toFixed(4);

const afterDecimalTwoDigit = (num) => parseFloat(num).toFixed(2);
const bytesToKB = (bytes) => bytes / 1024;
const DISABLED_GLOBAL_MATRICS_SCAN_BTN = true;
const DISABLED_GLOBAL_MATRICS_REMOVE_BTN = true;

export {
  afterDecimalFourDigit,
  afterDecimalTwoDigit,
  bytesToKB,
  DISABLED_GLOBAL_MATRICS_SCAN_BTN,
  DISABLED_GLOBAL_MATRICS_REMOVE_BTN,
};
