import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Row, Col } from "antd";
import PropTypes from "prop-types";
import { Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import WidgetMenu from "./WidgetMenu";
import { useSelector } from "react-redux";

const { Header: AntHeader } = Layout;

const Header = ({ toggleCollapsed, isCollapsed }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <AntHeader className="site-layout-background sideNav_header shadow-red-500/50">
      <Row>
        <Col span={6}>
          <button
            className="sideNav_button"
            data-testid="header-btn"
            onClick={toggleCollapsed}
            type="button"
          >
            {isCollapsed ? (
              <MenuUnfoldOutlined data-testid="unFoldIcon" data-id className="large_text" />
            ) : (
              <MenuFoldOutlined data-testid="foldIcon" className="large_text" />
            )}
          </button>
        </Col>
        <Col span={18}>
          <Row
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <span>
              <span className="h1 mr-2 capitalize">{`${user.firstName} ${user.lastName}`}</span>
              <span className="mr-2">{`(${user.roleName})`}</span>
            </span>
            <Dropdown
              data-testid="drop-down-profile"
              className={isCollapsed ? "collapsed-layout" : "non-collapsed-layout"}
              overlay={<WidgetMenu />}
            >
              <Avatar icon={<UserOutlined />} />
            </Dropdown>
          </Row>
        </Col>
      </Row>
    </AntHeader>
  );
};

Header.defaultProps = {
  isCollapsed: false,
};

Header.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
};

export default Header;
