import { useEffect, useState } from "react";
import { Col, Result, Row, Card } from "antd";
import roles from "config/roles";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { getUsersStats } from "redux/action/UserAction";
import { getCloudsStats } from "redux/action/CloudAccountActions";
import { getTeamsCount } from "redux/action/TeamAction";
import { getResourceCount } from "redux/action/ResourceAction";
import { getRecommendationsStats } from "redux/action/RecommendationActions";

const Home = () => {
  const dispatch = useDispatch();
  const {
    user: { role },
  } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.team);
  const { count: resourceCount } = useSelector((state) => state.resource);
  const { isLoadingUserStats, userStats } = useSelector((state) => state.user);
  const { isLoadingCLoudStats, cloudStats } = useSelector((state) => state.cloudAccount);
  const { isLoadingRecommendationStats, recommendationStats } = useSelector(
    (state) => state.recommendation
  );

  const [userData, setUserData] = useState([["User", "Count"]]);
  const [cloudData, setCloudData] = useState([["Provider", "Count"]]);
  const [recommendationData, setRecommendationData] = useState([["Status", "Count"]]);

  const userOptions = {
    title: "Active Users",
    pieHole: 0.4,
    is3D: false,
  };

  const CloudOptions = {
    title: "Cloud Accounts",
    pieHole: 0.4,
    is3D: false,
  };

  const recommendationOptions = {
    title: "Recommendations",
    pieHole: 0.4,
    is3D: false,
  };

  useEffect(() => {
    if ([roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER].includes(role)) {
      Promise.all([
        dispatch(roles.MANAGER === role ? getRecommendationsStats() : getUsersStats()),
        dispatch(getCloudsStats()),
        dispatch(getTeamsCount()),
        dispatch(getResourceCount()),
      ]);
    } else {
      // NOTE: If role is Team Member
      Promise.all([dispatch(getRecommendationsStats()), dispatch(getResourceCount())]);
    }
  }, []);

  useEffect(() => {
    if (isLoadingCLoudStats) {
      const clouds = (cloudStats || []).map((cloud) => [cloud._id, cloud.count]);
      setCloudData([...new Map([...cloudData, ...clouds])]);
    }
  }, [isLoadingCLoudStats]);

  useEffect(() => {
    if (isLoadingRecommendationStats) {
      const recommendations = (recommendationStats || []).map((recommendation) => [
        recommendation._id,
        recommendation.count,
      ]);
      setRecommendationData([...new Map([...recommendationData, ...recommendations])]);
    }
  }, [isLoadingRecommendationStats]);

  useEffect(() => {
    if (isLoadingUserStats) {
      const users = (userStats || []).map((user) => {
        return [user.role, user.count];
      });
      setUserData([...new Map([...userData, ...users])]);
    }
  }, [isLoadingUserStats]);

  return (
    <>
      {[roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER].includes(role) && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Chart
              chartType="PieChart"
              data={roles.MANAGER === role ? recommendationData : userData}
              options={roles.MANAGER === role ? recommendationOptions : userOptions}
              width="100%"
              height="400px"
            />
          </Col>
          <Col span={12}>
            <Chart
              chartType="PieChart"
              data={cloudData}
              options={CloudOptions}
              width="100%"
              height="400px"
            />
          </Col>

          <Col span={12} align="middle">
            <Card
              title="Team Management"
              extra={<a href="/dashboard/team/viewAllTeams">View All</a>}
              style={{ width: "80%" }}
            >
              Total Teams
              <h2>{count}</h2>
            </Card>
          </Col>
          <Col span={12} align="middle">
            <Card
              title="Resource Management"
              extra={<a href="/dashboard/resourcemanagment">View All</a>}
              style={{ width: "80%" }}
            >
              Total Resources
              <h2>{resourceCount}</h2>
            </Card>
          </Col>
        </Row>
      )}
      {role === roles.TEAM_MEMBER && (
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} xl={12}>
            <Chart
              chartType="PieChart"
              data={recommendationData}
              options={recommendationOptions}
              width="100%"
              height="400px"
            />
          </Col>
          <Col align="middle" xs={24} xl={12}>
            <Card
              title="Resource Management"
              extra={<a href="/dashboard/resourcemanagment">View All</a>}
              style={{ width: "80%" }}
            >
              Total Resources
              <h2>{resourceCount}</h2>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Home;
