import {
  HomeOutlined,
  UserOutlined,
  UserAddOutlined,
  TeamOutlined,
  CloudOutlined,
  ApiOutlined,
  WarningOutlined,
} from "@ant-design/icons";

export const admin = [
  {
    key: 1,
    label: "Home",
    route: "/",
    "data-testid": "home",
    icon: <HomeOutlined />,
  },
  {
    key: 2,
    label: "User Management",
    route: "",
    "data-testid": "user-managment",
    icon: <UserOutlined />,
    children: [
      {
        key: 2.1,
        label: "View All User",
        route: "user/viewAllUser",
        "data-testid": "view-all-user",
        icon: <TeamOutlined />,
      },
      {
        key: 2.2,
        label: "Add New User",
        route: "user/createUser",
        "data-testid": "add-new-user",
        icon: <UserAddOutlined />,
      },
    ],
  },
  {
    key: 3,
    label: "Team Management",
    route: "",
    "data-testid": "team-management",
    icon: <TeamOutlined />,
    children: [
      {
        key: 3.1,
        label: "View All Team",
        route: "team/viewAllTeams",
        "data-testid": "view-all-team",
        icon: <TeamOutlined />,
      },
      {
        key: 3.4,
        label: "Add New Team",
        route: "team/createTeam",
        "data-testid": "add-new-team",
        icon: <UserAddOutlined />,
      },
    ],
  },
  {
    key: 4,
    label: "Cloud Accounts Management",
    route: "",
    "data-testid": "cloud-account-managment",
    icon: <CloudOutlined />,
    children: [
      {
        key: 4.1,
        label: "View All Cloud Account",
        route: "cloud/viewAllClouds",
        "data-testid": "view-all-cloud",
        icon: <CloudOutlined />,
      },
      {
        key: 4.2,
        label: "Add New Profile",
        route: "cloud/addProfile",
        "data-testid": "add-new-profile",
        icon: <CloudOutlined />,
      },
    ],
  },
  {
    key: 5,
    label: "Help",
    route: "",
    "data-testid": "help",
    icon: <ApiOutlined />,
    children: [
      {
        key: 5.1,
        label: "User Manual",
        route: "usermanual",
        "data-testid": "user-manual",
        icon: <UserOutlined />,
      },
      {
        key: 5.2,
        label: "About",
        route: "about",
        "data-testid": "about",
        icon: <WarningOutlined />,
      },
    ],
  },
];
