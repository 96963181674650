import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const authLogin = createAsyncThunk("auth/login", async (loginCredentials, { rejectWithValue }) => {
  const options = {
    url: API_URL.LOGIN,
    method: "POST",
    data: {
      ...loginCredentials,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const authForgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (forgotCredentials, { rejectWithValue }) => {
    const options = {
      url: API_URL.FORGOT_PASSWORD,
      method: "POST",
      data: {
        ...forgotCredentials,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authResetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (resetPasswordRequest, { rejectWithValue }) => {
    const options = {
      url: API_URL.RESET_PASSWORD,
      method: "POST",
      data: {
        ...resetPasswordRequest,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authChangePassword = createAsyncThunk(
  "auth/changePassword",
  async (resetPasswordRequest, { rejectWithValue }) => {
    const options = {
      url: API_URL.CHANGE_PASSWORD,
      method: "POST",
      data: {
        ...resetPasswordRequest,
      },
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authUserProfile = createAsyncThunk("auth/profile", async (_, { rejectWithValue }) => {
  const options = {
    url: API_URL.USER_PROFILE,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { authLogin, authForgotPassword, authResetPassword, authUserProfile, authChangePassword };
