import {
  HomeOutlined,
  UserOutlined,
  CloudServerOutlined,
  FileTextOutlined,
  ApiOutlined,
  WarningOutlined,
  UnorderedListOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  RiseOutlined,
  CreditCardOutlined,
  LineChartOutlined,
  TeamOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

export const teamMember = [
  {
    key: 1,
    label: "Home",
    route: "/",
    "data-testid": "home",
    icon: <HomeOutlined />,
  },
  {
    key: 1.1,
    label: "Project Management",
    route: "project",
    "data-testid": "project",
    icon: <ProjectOutlined />,
    disabled: true,
  },
  {
    key: 2,
    label: "Resource Management",
    route: "",
    "data-testid": "resource-managment",
    icon: <CloudServerOutlined />,
    children: [
      {
        key: 2.1,
        label: "Resources",
        route: "resourcemanagment/resources",
        "data-testid": "resource",
        icon: <CloudServerOutlined />,
      },
      // {
      //   key: 2.2,
      //   label: "Usability Metrics",
      //   route: "resourcemanagment/usability-metrics",
      //   "data-testid": "usability-metrics",
      //   icon: <FundOutlined />,
      // },
      {
        key: 2.3,
        label: "Billing",
        route: "resourcemanagment/billing",
        "data-testid": "billing",
        icon: <CreditCardOutlined />,
      },
    ],
  },
  {
    key: 3,
    label: "Resource Utilization",
    route: "",
    "data-testid": "resourceUtilization",
    icon: <LineChartOutlined />,
    children: [
      {
        key: 3.1,
        label: "Details",
        route: "metrics/acquirer",
        "data-testid": "acquirer",
        icon: <FileTextOutlined />,
      },
      {
        key: 3.2,
        label: "Analysis",
        route: "metrics/analysis",
        "data-testid": "analysis",
        icon: <CopyOutlined />,
      },
    ],
  },
  {
    key: 4,
    label: "Recommendations",
    route: "recommendations-managment",
    "data-testid": "recommendations-managment",
    icon: <RiseOutlined />,
    // children: [
    //   {
    //     key: 4.1,
    //     label: "Generator",
    //     route: "recomendation/generator",
    //     "data-testid": "generator",
    //     icon: <RiseOutlined />,
    //   },
    //   {
    //     key: 4.2,
    //     label: "Executor",
    //     route: "recomendation/executor",
    //     "data-testid": "executor",
    //     icon: <CheckCircleOutlined />,
    //   },
    // ],
  },
  {
    key: 4.1,
    label: "Change Managment",
    route: "change-managment",
    "data-testid": "executor",
    icon: <CheckCircleOutlined />,
  },
  {
    key: 5,
    label: "History",
    route: "history",
    "data-testid": "history",
    disabled: true,
    icon: <UnorderedListOutlined />,
  },
  {
    key: 6,
    label: "Reports",
    route: "report",
    "data-testid": "report",
    // "disabled": true,
    icon: <UnorderedListOutlined />,
    children: [
      {
        key: 6.1,
        label: "Recommendation Summary",
        route: "report/recommendations_summary",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
      {
        key: 6.2,
        label: "Recommendation Details",
        route: "report/recommendations_details",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
      {
        key: 6.3,
        label: "Billing Per Team",
        route: "report/billing_per_team",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
    ],
  },
  {
    key: 7,
    label: "Help",
    route: "",
    "data-testid": "help",
    icon: <ApiOutlined />,
    children: [
      {
        key: 7.1,
        label: "User Manual",
        route: "usermanual",
        "data-testid": "user-manual",
        icon: <UserOutlined />,
      },
      {
        key: 7.2,
        label: "About",
        route: "about",
        "data-testid": "about",
        icon: <WarningOutlined />,
      },
    ],
  },
];
