import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllMetricTypes = createAsyncThunk("metricsType/all", async (_, { rejectWithValue }) => {
  const options = {
    url: API_URL.ALL_METRIC_TYPES,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { getAllMetricTypes };
