import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  Form,
  Button,
  Space,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject, getProjectFilter } from "redux/action";
import { NavLink } from "react-router-dom";
import {
  SearchOutlined,
  RedoOutlined,
  ProjectOutlined,
  BulbOutlined,
  CaretUpFilled,
  CaretDownFilled,
} from "@ant-design/icons";

const states = [{ value: "ACTIVE", title: "ACTIVE" }];

const ViewAllProject = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const { isLoading, projectData } = useSelector((state) => state.projects);

  const { projectsData, totalPages, currentPage, count } = projectData;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
      render: (text, value) => {
        return (
          <NavLink to={`/dashboard/resourcemanagment/resources/?projectId=${text}`}>
            <div>{text}</div>
          </NavLink>
        );
      },
    },
    {
      title: "Resource Count",
      dataIndex: "totalResources",
      key: "totalResources",
      width: 120,
    },
    {
      title: "Project Number",
      dataIndex: "projectNumber",
      key: "projectNumber",
      width: 150,
    },
    {
      title: "Current Month Bill",
      dataIndex: "currentMonth",
      key: "currentMonth",
      width: 150,
      render: (_, { currentMonth, difference, differencePercent }) => {
        return (
          <>
            {currentMonth}
            {difference == 0 ? "[]" : null}
            {difference > 0 ? <CaretDownFilled style={{ color: "green" }} /> : null}
            {difference < 0 ? <CaretUpFilled style={{ color: "red" }} /> : null}

            {difference ? differencePercent : null}
          </>
        );
      },
    },
    {
      title: "Last Month Bill",
      dataIndex: "lastMonth",
      key: "lastMonth",
      width: 100,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
  ].filter((item) => !item.hidden);

  const filterFields = [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 2,
      name: "projectNumber",
      label: "Project Number",
      placeholder: "Project Number",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 3,
      name: "projectId",
      label: "Project Id",
      placeholder: "Project Id",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 5,
      name: "state",
      label: "Status",
      placeholder: "Status",
      type: "Select",
      rules: [{ required: false }],
      optType: "Option",
      option: states,
    },
  ];

  useEffect(() => {
    getAllProjects();
  }, []);

  const onFormFinish = ({ name, projectNumber, projectId, state }) => {
    if (!name && !projectNumber && !projectId && !state) return;
    setSearchData({});
    const data = {
      name: name,
      projectNumber: Number(projectNumber),
      projectId: projectId,
      state: state,
    };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getProjectFilter(data)); //TODO::  filter
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getProjectFilter({ page: pageNum, ...searchData })) //TODO::  filter
      : dispatch(getAllProject({ page: pageNum }));
  };

  const getAllProjects = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllProject({ page: 1 }));
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <ProjectOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">View All Projects</span>
      </Col>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={6} xs={12} sm={6} lg={6} />
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllProjects}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={projectsData}
          isLoading={isLoading}
          rowKey="_id"
          pagination={{
            total: count,
            onChange: (pageNum) => onPageChange(pageNum),
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                console.log("record", record, "rowIndex", rowIndex);
              }, // click row
            };
          }}
        />
      </Layout>
    </Layout>
  );
};

export default ViewAllProject;
