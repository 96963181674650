import { CloudOutlined } from "@ant-design/icons";
import { DynamicField as CloudAccountField, Form, Row, Col, Button, Layout } from "components";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useDispatch } from "react-redux";
import { createCloudAccount } from "redux/action/CloudAccountActions";

// TODO: needs to adjust in to the fixtures files
const creatCloudAccountFields = [
  {
    id: 1,
    name: "name",
    label: "Name",
    placeholder: "name",
    type: "Text",
    subType: "Text",
    rules: [{ required: true }],
  },
  {
    id: 2,
    name: "provider",
    label: "Provider",
    placeholder: "Provider",
    type: "Select",
    rules: [{ required: true }],
    optType: "Option",
    option: [
      {
        value: "AWS",
        title: "AWS",
      },
      {
        value: "GCP",
        title: "GCP",
      },
      {
        value: "Azure",
        title: "Azure",
      },
      {
        value: "OCI",
        title: "OCI",
      },
    ],
  },
  {
    id: 3,
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Text",
    subType: "TextArea",
    rules: [{ required: false }],
  },
  {
    id: 4,
    name: "appToken",
    label: "Token",
    placeholder: "Token",
    type: "Text",
    subType: "TextArea",
    rules: [{ required: true }],
  },
  {
    id: 5,
    name: "enabled",
    label: "Status",
    type: "Switch",
    additionalProps: {
      checkedChildren: "Enabled",
      unCheckedChildren: "Disabled",
    },
    defaultChecked: true,
    rules: [{ required: false }],
  },
];

const CreateCloudAccount = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const onFormFinish = ({ name, provider, appToken, enabled, description }) => {
    const fieldsValue = {
      name,
      provider,
      enabled,
      description,
      appToken,
    };
    dispatch(createCloudAccount({ fieldsValue }));
    form.resetFields();
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <CloudOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">Add New Profile</span>
      </Col>
      <Layout className="mt-4 bg-transparent">
        <Form
          hideRequiredMark
          initialValues={{ enabled: true }}
          form={form}
          name="createMembershipForm"
          layout="vertical"
          onFinish={onFormFinish}
          validateMessages={UI_VALIDATION}
        >
          <Row className="fields-row" gutter={20} type="flex" justify="space-between">
            <CloudAccountField
              inputsData={creatCloudAccountFields}
              span={12}
              xs={24}
              sm={12}
              lg={12}
            />

            {/* TODO: add this into dynamic actions, if needed */}
            <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={UI_TEXT.CLOUD_ACCOUNT.CREATE_PROFILE}
              />
            </Col>
          </Row>
        </Form>
      </Layout>
    </Layout>
  );
};
export default CreateCloudAccount;
