import { Table, Layout } from "components";

const ViewAllMetrices = ({ metricsData }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <Layout className="bg-transparent">
      <Layout className="mt-4 bg-transparent">
        <Table columns={columns} dataSource={metricsData} rowKey="_id" />
      </Layout>
    </Layout>
  );
};

export default ViewAllMetrices;
