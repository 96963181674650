import { Layout } from "antd";
import PropTypes from "prop-types";

const { Footer: AntFooter } = Layout;

const Footer = ({ text }) => (
  <AntFooter data-testid="atom-footer" className="text-center">
    {text}
  </AntFooter>
);

Footer.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Footer;
