import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "routes";
import { ROUTES } from "constants";
import Login from "pages/Login";
import Dashboard from "pages/dashboard";
import Home from "pages/dashboard/Home";
import ViewAllUser from "pages/dashboard/users/ViewAllUser";
import CreateUser from "pages/dashboard/users/CreateUser";
import About from "pages/dashboard/About";
import UserManual from "pages/dashboard/UserManual";
import ResetPassword from "pages/ResetPassword";
import { useDispatch } from "react-redux";
import { authUserProfile } from "redux/action";
import { useLocalStorage } from "hooks";
import roles from "config/roles";
import NoMatch from "pages/NoMatch";
import UpdateUser from "pages/dashboard/users/UpdateUser";
import axios from "axios";
import { clearUser } from "redux/reducers/AuthReducer";
import ViewAllTeam from "pages/dashboard/team/ViewAllTeam";
import CreateTeam from "pages/dashboard/team/CreateTeam";
import UpdateTeam from "pages/dashboard/team/UpdateTeam";
import ViewAllCloudAccount from "pages/dashboard/cloud-account/ViewAllCloudAccount";
import CreateCloudAccount from "pages/dashboard/cloud-account/CreateCloudAccount";
import UpdateCloudAccount from "pages/dashboard/cloud-account/UpdateCloudAccount";
import ViewAllResource from "pages/dashboard/resource/ViewAllResource";
import UpdateResource from "pages/dashboard/resource/UpdateResource";
import UsabilityMetrics from "pages/dashboard/resource/UsabilityMetrics";
import UsabilityMetricsResource from "pages/dashboard/resource/UsabilityMetricsResource";
import ViewAllRecommendation from "pages/dashboard/recommendation/ViewAllRecommendation";
import UpdateRecommendation from "pages/dashboard/recommendation/UpdateRecommendation";
import MetricsAcquirer from "pages/dashboard/metrics/MetricsAcquirer";
import MetricsAggregator from "pages/dashboard/metrics/MetricsAggregator";
import RecommendationGenerator from "pages/dashboard/metrics/RecommendationGenerator";
import RecommendationExecutor from "pages/dashboard/metrics/RecommendationExecutor";
import Reports from "pages/dashboard/reports/Reports";
import ReportsBillingPerTeam from "pages/dashboard/reports/BillingPerTeam";
import ReportsRecommendationsAggregates from "pages/dashboard/reports/RecommendationsAggregates";
import ReportsRecommendationsDetails from "pages/dashboard/reports/RecommendationsDetails";
import ReportsResourcesWithoutLabels from "pages/dashboard/reports/ResourcesWithoutLabels";
import Billing from "pages/dashboard/billing/Billing";
import ViewAllProject from "pages/dashboard/project/ViewAllProject";
import MetricsInformation from "pages/dashboard/metrics/MetricsMoreInformation";

const App = () => {
  const dispatch = useDispatch();
  const { getItem, removeItem } = useLocalStorage();

  useEffect(() => {
    const accessToken = getItem("access_token");
    accessToken && dispatch(authUserProfile());
  }, []);

  // axios interceptors, means if any network call responds 401 it redirect to login page.
  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 401) {
      dispatch(clearUser());
      removeItem("access_token");
    }
    return Promise.reject(err);
  });

  return (
    <Router>
      <Routes>
        <Route
          index
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES?.RESET_PASSWORD}
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path={ROUTES?.ADMIN}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          element={
            <PrivateRoute
              roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.TEAM_MEMBER, roles.MANAGER]}
            >
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route index element={<Home />} />
        </Route>
        {/* <Route
          path={ROUTES?.SEARCH_MEMBERSHIP}
          element={<SearchMembership />}
        />
        <Route
          path={ROUTES?.CREATE_MEMBERSHIP}
          element={<CreateMemberShip />}
        /> */}
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.ALL_USERS} element={<ViewAllUser />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.CREATE_USER} element={<CreateUser />} />
        </Route>
        <Route
          element={
            <PrivateRoute
              roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.TEAM_MEMBER, roles.MANAGER]}
            >
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.UPDATE_USER} element={<UpdateUser />} />
        </Route>

        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.VIEW_CLOUDS} element={<ViewAllCloudAccount />} />
          <Route path={ROUTES?.VIEW_PROJECT} element={<ViewAllProject />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.ADD_CLOUD_PROFILE} element={<CreateCloudAccount />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.UPDATE_CLOUD_PROFILE} element={<UpdateCloudAccount />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.MANAGER]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.ALL_TEAMS} element={<ViewAllTeam />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.CREATE_TEAM} element={<CreateTeam />} />
        </Route>
        <Route
          element={
            <PrivateRoute roles={[roles.SUPER_ADMIN, roles.ADMIN]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.UPDATE_TEAM} element={<UpdateTeam />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.TEAM_MEMBER, roles.MANAGER]}
            >
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.ABOUT} element={<About />} />
        </Route>
        <Route
          element={
            <PrivateRoute
              roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.TEAM_MEMBER, roles.MANAGER]}
            >
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.USER_MANUAL} element={<UserManual />} />
        </Route>

        <Route
          element={
            <PrivateRoute roles={[roles.TEAM_MEMBER, roles.MANAGER]}>
              <Dashboard />
            </PrivateRoute>
          }
          path={ROUTES?.DASHBOARD}
        >
          <Route path={ROUTES?.METRICS_ANALYSIS} element={<MetricsAggregator />} />
          <Route path={ROUTES?.METRICS_ACQUIRER} element={<MetricsAcquirer />} />
          <Route path={ROUTES?.MORE_INFORMATION} element={<MetricsInformation />} />
          <Route path={ROUTES?.RESOURCE_MANAGEMENT} element={<ViewAllResource />} />
          <Route path={ROUTES?.UPDATE_RESOURCE} element={<UpdateResource />} />
          <Route
            path={ROUTES?.HISTORY}
            element={<ViewAllRecommendation status={ROUTES.HISTORY} isHistory={true} />}
          />
          <Route path={ROUTES?.RECOMMENDATION_DETAIL} element={<UpdateRecommendation />} />
          <Route path={ROUTES?.METRICS_USABILITY} element={<UsabilityMetrics />} />
          <Route path={ROUTES?.METRICS_USABILITY_RESOURCE} element={<UsabilityMetricsResource />} />
          <Route path={ROUTES?.BILLING} element={<Billing />} />
          <Route path={ROUTES?.RECOMMENDATIONS} element={<ViewAllRecommendation />} />
          <Route path={ROUTES?.RECOMMENDATION_MANAGMENT} element={<RecommendationGenerator />} />
          <Route path={ROUTES?.CHANGE_MANAGMENT} element={<RecommendationExecutor />} />
          <Route path={ROUTES?.REPORT} element={<Reports />} />
          <Route
            path={ROUTES?.REPORT_RECOMMENDATIONS_SUMMARY}
            element={<ReportsRecommendationsAggregates />}
          />
          <Route
            path={ROUTES?.REPORT_RECOMMENDATIONS_DETAILS}
            element={<ReportsRecommendationsDetails />}
          />
          <Route path={ROUTES?.REPORT_BILLING_PER_TEAM} element={<ReportsBillingPerTeam />} />
          <Route
            path={ROUTES?.REPORT_UNASSIGNED_RESOURCES}
            element={<ReportsResourcesWithoutLabels />}
          />
        </Route>
        <Route path="*" exact element={<NoMatch />} />
      </Routes>
    </Router>
  );
};

export default App;
