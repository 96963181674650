import {
  Table,
  Layout,
  Button,
  Col,
  Row,
  DynamicField as FilterField,
  Form,
  Space,
} from "components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchOutlined,
  DeleteOutlined,
  CloudServerOutlined,
  RedoOutlined,
  CaretDownFilled,
  CaretUpFilled,
} from "@ant-design/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getAllResources, scanResources, getResourceFilter } from "redux/action";
import { usePermission } from "hooks";
import roles from "config/roles";

// TODO: needs to adjust in to the fixtures files

const filterFields = [
  {
    id: 1,
    name: "name",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "type",
    label: "Type",
    placeholder: "gce_instance",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 4,
    name: "zone",
    label: "Zone",
    placeholder: "us-central1-a",
    type: "Text",
    rules: [{ required: false }],
  },
];

const ViewAllResource = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const { isLoading, resourceData, resourceFilterData } = useSelector((state) => state.resource);
  const { totalPages, currentPage, count } = resourceFilterData;

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const columns = [
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, value) => {
        return (
          <NavLink to={`/dashboard/resourcemanagment/usability-metrics/${value._id}`}>
            <div>{text}</div>
          </NavLink>
        );
      },
    },
    {
      title: "Current Month Bill",
      dataIndex: "currentMonth",
      key: "currentMonth",
      render: (_, { currentMonth, difference, differencePercent }) => {
        return (
          <>
            {currentMonth}
            {difference == 0 ? "[]" : null}
            {difference > 0 ? <CaretDownFilled style={{ color: "green" }} /> : null}
            {difference < 0 ? <CaretUpFilled style={{ color: "red" }} /> : null}

            {difference ? differencePercent : null}
          </>
        );
      },
    },
    {
      title: "Last Month Bill",
      dataIndex: "lastMonth",
      key: "lastMonth",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Cloud Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Action",
      key: "action",
      hidden: usePermission([roles.SUPER_ADMIN, roles.ADMIN]),
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/resourcemanagment/updateResource/${record?._id}`}>Edit</Link>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    isSearchQueryParams() ? filterQueryParams() : getResource();
  }, []);

  const getResource = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllResources());
  };

  const handleScanResources = () => {
    dispatch(scanResources());
  };

  const onPageChange = (pageNum) =>
    isFilter ? dispatch(getResourceFilter({ page: pageNum, ...searchData })) : null;

  const onFormFinish = (params) => {
    const { name, type, zone, projectId } = params;
    if (!name && !type && !zone && !projectId) return;
    setSearchData({});

    const data = { ...params };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getResourceFilter(data));
    setSearchData(data);
    setIsFilter(true);
  };

  const isSearchQueryParams = () => {
    if (query.get("projectId")) {
      const projectId = query.get("projectId");
      form.setFieldsValue({
        projectId,
      });
      setSearchData({ projectId });
      dispatch(getResourceFilter({ projectId }));

      return true;
    }
    return false;
  };

  const filterQueryParams = () => {
    setIsFilter(true);
  };

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CloudServerOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Resources</span>
        </Col>
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-end">
          <Space>
            <Button
              text="Scan"
              icon={<SearchOutlined />}
              onClick={handleScanResources}
              // disabled={DISABLED_GLOBAL_MATRICS_SCAN_BTN}
            />
          </Space>
        </Col>
      </Row>
      {/* Filter Form */}
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={8} xs={24} sm={8} lg={8} />

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getResource}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>

      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={resourceData}
          isLoading={isLoading}
          rowKey="_id"
          pagination={{ total: count, onChange: (pageNum) => onPageChange(pageNum) }}
        />
      </Layout>
    </Layout>
  );
};

export default ViewAllResource;
