import { Form, Switch as AntSwitch } from "antd";
import { PropTypes } from "prop-types";

const Switch = ({ name, validationRules, label, defaultChecked, additionalProps }) => {
  return (
    <Form.Item name={name} label={label} rules={validationRules} valuePropName="checked">
      <AntSwitch data-testid="atom-switch" defaultChecked={defaultChecked} {...additionalProps} />
    </Form.Item>
  );
};

Switch.defaultProps = {
  additionalProps: {},
  validationRules: [],
  label: "check",
  defaultChecked: false,
};

Switch.propTypes = {
  additionalProps: PropTypes.shape({}),
  validationRules: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
};

export default Switch;
