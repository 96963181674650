import { Form, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PropTypes } from "prop-types";
import { Button, DynamicField } from "components";

const DynamicList = (props) => {
  const {
    item: { name, inputsData, label, span, lg, sm, xs },
  } = props;
  return (
    <>
      <h4>{label}:</h4>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row
                align="baseline"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <DynamicField
                  inputsData={inputsData}
                  name={name}
                  span={span}
                  lg={lg}
                  sm={sm}
                  xs={xs}
                  {...restField}
                />
                <MinusCircleOutlined
                  onClick={() => remove(name)}
                  style={{
                    marginTop: 38,
                  }}
                />
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                text={`Add ${label}`}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

DynamicList.defaultProps = {
  additionalProps: {},
};

DynamicList.propTypes = {
  additionalProps: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default DynamicList;
