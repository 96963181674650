import { createSlice } from "@reduxjs/toolkit";
import { toast } from "utils";
import {
  getAllCloudAccounts,
  createCloudAccount,
  deleteCloudAccount,
  updateCloudAccount,
  getCloudAccount,
  getCloudAccountFilters,
} from "redux/action";
import { getCloudsStats } from "redux/action/CloudAccountActions";

const initialCloudAccountState = {
  isLoading: false,
  isLoadingCloudAccount: false,
  isDeleteCloudAccount: false,
  cloudAccountProfile: {},
  cloudAccountData: [],
  errorMessage: {},
  successMessage: "",
  cloudStats: {},
  isLoadingCLoudStats: false,
};

const CloudAccountReducer = createSlice({
  name: "cloudAccount",
  initialState: initialCloudAccountState,
  reducers: {
    removeCloudAccount: (state, action) => {
      state.cloudAccountData.splice(
        state.cloudAccountData.findIndex((team) => team._id === action.payload),
        1
      );
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllCloudAccounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cloudAccountData = payload?.data;
    });
    builder.addCase(getCloudAccountFilters.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.cloudAccountData = payload?.data;
    });
    builder.addCase(getCloudsStats.fulfilled, (state, { payload }) => {
      state.cloudStats = payload?.data;
      state.isLoadingCLoudStats = true;
    });
    builder.addCase(createCloudAccount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast("success", "Cloud Account successfully created");
    });
    builder.addCase(updateCloudAccount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast("success", "Cloud Account successfully updated");
    });
    builder.addCase(deleteCloudAccount.fulfilled, (state, { payload }) => {
      state.isDeleteCloudAccount = true;
      toast("success", payload.message);
    });
    builder.addCase(getCloudAccount.fulfilled, (state, { payload }) => {
      state.isLoadingCloudAccount = true;
      state.cloudAccountProfile = payload?.data;
    });
    // Pending
    builder.addCase(getAllCloudAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCloudAccountFilters.pending, (state, action) => {
      state.isLoading = true;
      state.cloudAccountData = [];
    });
    builder.addCase(createCloudAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCloudAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCloudAccount.pending, (state, action) => {
      state.isDeleteCloudAccount = false;
    });
    builder.addCase(getCloudAccount.pending, (state, action) => {
      state.isLoadingCloudAccount = false;
    });
    builder.addCase(getCloudsStats.pending, (state, action) => {
      state.isLoadingCLoudStats = false;
    });
    // rejected
    builder.addCase(getAllCloudAccounts.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(getCloudAccountFilters.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(createCloudAccount.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(updateCloudAccount.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(deleteCloudAccount.rejected, (state, action) => {
      state.isDeleteCloudAccount = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(getCloudAccount.rejected, (state, action) => {
      state.isLoadingCloudAccount = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(getCloudsStats.rejected, (state, action) => {
      state.isLoadingCLoudStats = false;
      toast("error", action?.payload?.data?.message);
    });
  },
});

const { actions } = CloudAccountReducer;
export const { removeCloudAccount } = actions;
export default CloudAccountReducer.reducer;
