import { UI_VALIDATION } from "constants";

const numberValidation = () => ({
  validator(rule, value) {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

    if (!value) {
      return Promise.resolve();
    }
    if (!re.test(value)) {
      return Promise.reject(new Error(UI_VALIDATION.types.number));
    }
    return Promise.resolve();
  },
});

const organizationValidEmail = () => ({
  validator(rule, value) {
    const regEx = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(nisum)\.com$/;

    if (!value) {
      return Promise.resolve();
    }
    if (!regEx.test(value)) {
      return Promise.reject(new Error(UI_VALIDATION.organization.email));
    }
    return Promise.resolve();
  },
});

const customValidPassword = () => ({
  validator(rule, value) {
    const regEx = /(?=.*?[A-Z]).{8,}/;

    if (!value) {
      return Promise.resolve();
    }
    if (!regEx.test(value)) {
      return Promise.reject(new Error(UI_VALIDATION.invalidPassword));
    }
    return Promise.resolve();
  },
});

const customPhoneNumber = () => ({
  validator(rule, value) {
    const regEx = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (!value) {
      return Promise.resolve();
    }
    if (!regEx.test(value)) {
      return Promise.reject(new Error(UI_VALIDATION.invalidCellNumber));
    }
    return Promise.resolve();
  },
});

export { numberValidation, organizationValidEmail, customValidPassword, customPhoneNumber };
