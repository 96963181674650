import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllBillingReports = createAsyncThunk("reports/billing", async (params, { rejectWithValue }) => {
  const { page = 1 } = params;
  const options = {
    url: `${API_URL.REPORTS}/billing?page=${page}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});
const getBillingReports = createAsyncThunk(
  "reports/billing/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/billing?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getAllBillingPerTeamReports = createAsyncThunk(
  "reports/billingPerTeam",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/billingPerTeam?page=${page}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getFilterBillingPerTeamReports = createAsyncThunk(
  "reports/billingPerTeam/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/billingPerTeam?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };

    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getAllRecommendationsAggregatesReports = createAsyncThunk(
  "reports/recommendationsAggregates",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/recommendationsAggregates?page=${page}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getFilterRecommendationsAggregatesReports = createAsyncThunk(
  "reports/recommendationsAggregates/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/recommendationsAggregates?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };

    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const downloadCSVRecommendationsAggregatesReports = createAsyncThunk(
  "reports/recommendationsAggregates/csv",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.REPORTS}/recommendationsAggregates/csv`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };

    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getAllRecommendationsDetailsReports = createAsyncThunk(
  "reports/recommendationsDetails",
  async (params, { rejectWithValue }) => {
    const { page } = params;
    const options = {
      url: `${API_URL.REPORTS}/recommendationsDetails?page=${page}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getFilterRecommendationsDetailsReports = createAsyncThunk(
  "reports/recommendationsDetails/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/recommendationsDetails?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };

    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getAllResourcesWithoutLabelsReports = createAsyncThunk(
  "reports/resourcesWithoutLabels",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/resourcesWithoutLabels?page=${page}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getFilterResourcesWithoutLabelsReports = createAsyncThunk(
  "reports/resourcesWithoutLabels/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.REPORTS}/resourcesWithoutLabels?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };

    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  // Billing
  getAllBillingReports,
  getBillingReports,
  // Recommendations Summary Reports
  getAllRecommendationsAggregatesReports,
  getFilterRecommendationsAggregatesReports,
  downloadCSVRecommendationsAggregatesReports,
  // Recommendations Details Reports
  getAllRecommendationsDetailsReports,
  getFilterRecommendationsDetailsReports,
  // Billing Per Team Reports
  getAllBillingPerTeamReports,
  getFilterBillingPerTeamReports,
  // Resources Without Labels Reports
  getAllResourcesWithoutLabelsReports,
  getFilterResourcesWithoutLabelsReports,
};
