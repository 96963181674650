import { admin as adminMenu } from "roles/admin";
import { manager as managerMenu } from "roles/manager";
import { teamMember as teamMemberMenu } from "roles/team-member";

const rolesMenu = {
  // NOTE: Both admin and super admin have same routing
  1: adminMenu,
  2: adminMenu,
  3: teamMemberMenu,
  4: managerMenu,
};

export { rolesMenu, adminMenu, teamMemberMenu, managerMenu };
