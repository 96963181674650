import { PictureFilled } from "@ant-design/icons";
import logo from "assets/images/ccoe_logo.png";
import { Menu, Layout, Logo } from "components/atoms";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from "hooks";
import { clearUser } from "redux/reducers/AuthReducer";
import { useState, useEffect } from "react";
import { rolesMenu } from "roles";

const SideNav = ({ isCollapsed }) => {
  const [activeRoutes, setActiveRoutes] = useState(rolesMenu[1]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removeItem } = useLocalStorage();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.role) {
      setActiveRoutes(rolesMenu[user.role]);
    }
  }, [user.role]);

  const handleOnMenuClick = ({ item }) => {
    const { route, title } = item?.props;
    if (title === "logout") {
      dispatch(clearUser());
      removeItem("access_token");
      navigate("/");
    } else {
      route && navigate(route);
    }
  };

  return (
    <Layout.Sider
      data-testid="nav-slider"
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      width={300}
      theme="light"
      className="sideNav_sider"
    >
      <div className="logo" data-testid="logo-img">
        <Logo image={logo} altText="CCOE Logo" additionalProps={{ className: "w-7/12" }} />
      </div>
      <Menu
        menuItems={activeRoutes}
        icon={<PictureFilled />}
        handleOnMenuClick={(item) => handleOnMenuClick(item)}
      />
    </Layout.Sider>
  );
};
SideNav.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

export default SideNav;
