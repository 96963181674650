import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  DatePicker,
  Form,
  Button,
  Space,
  Tag,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, LineChartOutlined, RedoOutlined } from "@ant-design/icons";
import { getAllMetrics, getAllMetricTypes, getAllMetricsFilter } from "redux/action";
import { afterDecimalFourDigit, bytesToKB } from "utils/helper";
import moment from "moment";
// TODO: needs to adjust in to the fixtures files

const filterFields = [
  {
    id: 1,
    name: "resource",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
];

const MetricsAcquirer = () => {
  const limit = 50;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [metricsAnalysis, setMetricsAnalysis] = useState([]);
  const [isLoadingTransform, setIsLoadingTransform] = useState(true);

  const { isLoading, metricData } = useSelector((state) => state.metrics);
  const { metricsData, currentPage, count, totalPages } = metricData;

  const filterMetricTypes = (metric) => {
    if (metric) {
      const { ccoeeMetricType, value } = metric;
      // const { name, unit } = metricTypesData[ccoeeMetricType];
      return (
        <>
          <Tag>{ccoeeMetricType}</Tag>
          <br />
          <Tag>{afterDecimalFourDigit(value)}</Tag>
        </>
      );
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resourceName",
    },
    {
      title: "Metric 1",
      dataIndex: "0",
      key: "0",
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 2",
      dataIndex: "1",
      key: "1",
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 3",
      dataIndex: "2",
      key: "2",
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 4",
      dataIndex: "3",
      key: "3",
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 5",
      dataIndex: "4",
      key: "4",
      render: (metric) => filterMetricTypes(metric),
    },
  ];

  useEffect(() => {
    dispatch(getAllMetricTypes());
    getAllMetricsDetails();
  }, []);

  useEffect(() => {
    if (metricsData) {
      setIsLoadingTransform(true);
      const transformData = metricsData.map((item) => {
        const { metricsDetailList } = item;
        const result = metricsDetailList.map((item, ind) => item);
        return { ...item, ...result };
      });
      setMetricsAnalysis(transformData);
      setIsLoadingTransform(false);
    }
  }, [metricsData]);

  const onFormFinish = ({ resource, month, projectId }) => {
    if (!resource && !projectId && !month) return;

    setSearchData({});
    const data = {
      month: month ? moment(month).format("DD/MM/YY").toUpperCase() : null, //03/05/23
      resource: resource,
      projectId: projectId,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }

    dispatch(getAllMetricsFilter(data));
    setSearchData(data);
    setIsFilter(true);
    // form.resetFields();
  };

  const getAllMetricsDetails = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(
      getAllMetrics({
        page: 1,
      })
    );
  };

  const onPageChange = (pageNum) =>
    isFilter
      ? dispatch(getAllMetricsFilter({ page: pageNum, ...searchData }))
      : dispatch(
          getAllMetrics({
            page: pageNum,
          })
        );

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <LineChartOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Metrics Details</span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={8} xs={8} sm={8} lg={8} />
          <Col span={8} xs={8} sm={8} lg={8} className="flex ">
            {/* <DatePicker name="month" label="Select Month" /> */}
          </Col>

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllMetricsDetails}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={metricsAnalysis}
          isLoading={isLoading || isLoadingTransform}
          pagination={{ total: count, onChange: (e) => onPageChange(e) }}
          rowKey="_id"
        />
      </Layout>
    </Layout>
  );
};

export default MetricsAcquirer;
