import { Space } from "components/atoms";
import { PropTypes } from "prop-types";
import logo from "assets/images/ccoe_logo.png";
import { Logo } from "components/atoms";

const LoginContainer = ({ children }) => (
  <Space direction="vertical" className="w-90">
    <div className="logo">
      <Logo image={logo} altText="CCOE Logo" additionalProps={{ className: "w-7/12" }} />
    </div>
    {children}
  </Space>
);

LoginContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginContainer;
