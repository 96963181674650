import { Layout, Col, Row, MetricCardList, Tag } from "components";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMetricsAggregatorsById, getAllMetricTypes } from "redux/action";
import { CopyOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const MetricsInformation = () => {
  const dispatch = useDispatch();

  const { isLoading, metricAggregatorData } = useSelector((state) => state.metricAggregator);
  const { metricAggregatorsData, count } = metricAggregatorData;
  const { metricTypesData } = useSelector((state) => state.metricTypes);

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    dispatch(getAllMetricTypes());
    getMetricsAggregatorsDetailsById();
  }, []);

  const getMetricsAggregatorsDetailsById = () => {
    const _id = query.get("metric");
    dispatch(
      getMetricsAggregatorsById({
        page: 1,
        limit: 10,
        id: _id,
      })
    );
  };

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CopyOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Metrics More Information</span>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={6} xs={6} sm={6} lg={6}>
          <b>ProjectId</b>
          <p>{metricAggregatorsData[0]?.projectId}</p>
        </Col>
        <Col span={6} xs={6} sm={6} lg={6}>
          <b>Resource</b>
          <p>{metricAggregatorsData[0]?.resource}</p>
        </Col>
        <Col span={6} xs={6} sm={6} lg={6}>
          <b>From Date</b>
          <p>{metricAggregatorsData[0]?.fromDate}</p>
        </Col>
        <Col span={6} xs={6} sm={6} lg={6}>
          <b>To Date</b>
          <p>{metricAggregatorsData[0]?.toDate}</p>
        </Col>
      </Row>
      <Layout className="mt-4 bg-transparent">
        {isLoading ? (
          <p>loading</p>
        ) : (
          <Row>
            <MetricCardList data={metricAggregatorsData[0]?.metricAggregatorDetailList} />
          </Row>
        )}
      </Layout>
    </Layout>
  );
};

export default MetricsInformation;
