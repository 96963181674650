import { Table, Layout, Button, Col, Row } from "components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { Link } from "react-router-dom";
import { getAllResources } from "redux/action";
import { usePermission } from "hooks";
import roles from "config/roles";
// TODO: needs to adjust in to the fixtures files

const Reports = () => {
  const dispatch = useDispatch();
  const { isLoading, resourceData } = useSelector((state) => state.resource);

  const uniqueResource = () => {
    let unique = [...new Set(resourceData.map((item) => item.name))];
    return unique.map((item) => ({ text: item, value: item }));
  };

  const uniqueType = () => {
    let unique = [...new Set(resourceData.map((item) => item.type))];
    return unique.map((item) => ({ text: item, value: item }));
  };

  const uniqueZone = () => {
    let unique = [...new Set(resourceData.map((item) => item.type))];
    return unique.map((item) => ({ text: item, value: item }));
  };

  const columns = [
    {
      title: "Project Id",
      dataIndex: "", //TODO:: key `projectId` comes from cloudProfile table
      key: "id",
      render: (record) => <span>{record.cloudProfile.projectId}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filters: uniqueResource(),
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: uniqueType(),
      filterSearch: true,
      onFilter: (value, record) => record.type.startsWith(value),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    // {
    //   title: "Team",
    //   dataIndex: "teamName",
    //   key: "teamName",
    // },
    {
      title: "Cloud",
      dataIndex: "", //TODO:: key `provider` comes from cloudProfile table
      key: "cloudName",
      render: (record) => <span>{record.cloudProfile.provider}</span>,
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      filters: uniqueZone(),
      filterSearch: true,
      onFilter: (value, record) => record.zone.startsWith(value),
    },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   key: "tags",
    //   render: (tags) => tags?.map(tag => `${tag.key}: ${tag.value}`).join(),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "enabled",
    //   key: "enabled",
    //   render: (_, record) => (
    //     <Tag color={record?.enabled ? "green" : "pink"}>
    //       {record.enabled ? "ENABLED" : "DISABLED"}
    //     </Tag>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      hidden: usePermission([roles.SUPER_ADMIN, roles.ADMIN]),
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/resourcemanagment/updateResource/${record?._id}`}>Edit</Link>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    dispatch(getAllResources());
  }, []);

  const handleRecommendationPerMonth = () => {
    alert("Recommendation Per Month");
  };

  const handleRecommendationPerTeam = () => {
    alert("Recommendation Per Team");
  };

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <UnorderedListOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2">Reports </span>
        </Col>
        {/* <Col span={12} xs={12} sm={12} lg={12} className="flex justify-end">
          <Space>
            <Button
              text="Recommendations per month"
              icon={<BulbOutlined />}
              onClick={handleRecommendationPerMonth}
            />
            <Button
              text="Recommendations per team"
              icon={<BulbOutlined />}
              onClick={handleRecommendationPerTeam}
            />
          </Space>
        </Col> */}
      </Row>
      {/* <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
        <Space>
          <Button text="Scan" icon={<SearchOutlined />}  onClick={handleScanResources} />
          <Button text="Remove Resources" icon={<DeleteColumnOutlined />} onClick={handleDeleteResources} type="danger" disabled={false} />
        </Space>
      </Col> */}
      {/* <Layout className="mt-4 bg-transparent">
        <Table columns={columns} dataSource={resourceData} isLoading={isLoading} rowKey="_id" />
      </Layout> */}
    </Layout>
  );
};

export default Reports;
