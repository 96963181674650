import { Table as AntTable } from "antd";
import PropTypes from "prop-types";
import "./style.less";

const Table = ({ columns, dataSource, isLoading, pagination, rowKey, scroll, props }) => (
  <AntTable
    data-testid="atom-table"
    rowKey={rowKey}
    columns={columns}
    dataSource={dataSource}
    loading={isLoading}
    pagination={{ ...pagination, showSizeChanger: false, pageSize: 50 }}
    showSizeChanger={false}
    scroll={scroll}
    defaultCurrent={1}
  />
);
Table.defaultProps = {
  dataSource: [],
  isLoading: false,
  totalSize: 0,
  pageSize: 0,
  pagination: {},
  scroll: {
    x: 1500,
    y: 450,
  },
};
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  totalSize: PropTypes.number,
  rowKey: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  scroll: PropTypes.shape({}),
  pagination: PropTypes.shape({
    total: PropTypes.number,
    pageSize: PropTypes.number,
    onChange: PropTypes.func,
    showTotal: PropTypes.func,
  }),
};

export default Table;
