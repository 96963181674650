import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "redux/reducers/AuthReducer";
import { useLocalStorage } from "hooks";
import { Menu } from "antd";

const WidgetMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removeItem } = useLocalStorage();
  const { user } = useSelector((state) => state.auth);

  const items = [
    { label: "My Profile", key: "my-profile", "data-testid": "my-profile" },
    { label: "Logout", key: "logout", "data-testid": "log-out" },
  ];

  const onClick = ({ key }) => {
    if (key === "logout") {
      dispatch(clearUser());
      removeItem("access_token");
      navigate("/");
    } else {
      navigate(`/dashboard/user/updateUser/${user._id}`);
    }
  };

  return <Menu style={{ width: 250 }} items={items} onClick={onClick} />;
};

export default WidgetMenu;
