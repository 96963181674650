import { Form, Select as AntSelect } from "antd";
import { PropTypes } from "prop-types";

// Add more custom props as per need
const Select = ({
  name,
  placeholder,
  validationRules,
  option,
  optType,
  additionalProps,
  label,
  disabled,
}) => {
  const optionType = {
    Option: AntSelect.Option,
    OptGroup: AntSelect.OptGroup,
  };
  const GenericOptionType = optionType["Option"];

  return (
    <Form.Item name={name} rules={validationRules} label={label}>
      <AntSelect
        data-testid="atom-select"
        placeholder={placeholder}
        disabled={disabled}
        {...additionalProps}
      >
        {option.map(({ value, title, isDisabled }, ind) => (
          <GenericOptionType
            disabled={isDisabled}
            data-testid="atom-option"
            key={`${ind}-${value}`}
            value={`${value}`}
          >
            {`${title}`}
          </GenericOptionType>
        ))}
      </AntSelect>
    </Form.Item>
  );
};

Select.defaultProps = {
  placeholder: "no text",
  additionalProps: {},
  optType: "Option",
  validationRules: [{}],
  label: "",
};

Select.propTypes = {
  placeholder: PropTypes.string,
  optType: PropTypes.string,
  additionalProps: PropTypes.shape({}),
  option: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  validationRules: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])),
  label: PropTypes.string,
};

export default Select;
