import { DynamicField as ResourceField, Form, Row, Col, Button } from "components";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCloudAccounts, getAllTeams, getResource, updateResource } from "redux/action";
import moment from "moment";

const UpdateResource = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoadingResource, resourceProfile } = useSelector((state) => state.resource);
  const { teamData } = useSelector((state) => state.team);
  const { cloudAccountData } = useSelector((state) => state.cloudAccount);

  const [form] = Form.useForm();

  let updateResourceFields = [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "Text",
      subType: "Text",
      rules: [{ required: true }],
      disabled: true,
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      placeholder: "Description",
      type: "Text",
      subType: "TextArea",
      additionalProps: {
        maxLength: 100,
        rows: 4,
      },
      rows: 4,
      rules: [{ required: false }],
    },
    {
      id: 3,
      name: "teamId",
      label: "Team",
      placeholder: "Team",
      type: "Select",
      rules: [{ required: true }],
      option: (teamData || []).map((d) => ({
        value: d._id,
        title: d.name,
      })),
      disabled: true,
    },
    {
      id: 4,
      name: "cloudId",
      label: "Cloud",
      placeholder: "Cloud",
      type: "Select",
      rules: [{ required: true }],
      option: (cloudAccountData || []).map((d) => ({
        value: d._id,
        title: d.name,
      })),
      disabled: true,
    },
    {
      id: 5,
      name: "tags",
      label: "Tags",
      placeholder: "Tags",
      type: "List",
      span: 10,
      xs: 10,
      sm: 10,
      lg: 10,
      inputsData: [
        {
          id: 1,
          name: "key",
          label: "Key",
          placeholder: "Key",
          type: "Text",
          subType: "Text",
          isTypeList: true,
          rules: [{ required: true }],
        },
        {
          id: 2,
          name: "value",
          label: "Value",
          placeholder: "Value",
          type: "Text",
          subType: "Text",
          isTypeList: true,
          rules: [{ required: true }],
        },
      ],
    },
    {
      id: 6,
      name: "enabled",
      label: "Status",
      type: "Switch",
      additionalProps: {
        checkedChildren: "Enabled",
        unCheckedChildren: "Disabled",
      },
    },
  ];

  const onFormFinish = (fieldsValue) => {
    dispatch(updateResource({ fieldsValue, id }));
  };

  useEffect(() => {
    dispatch(getResource({ id }));
    dispatch(getAllTeams());
    dispatch(getAllCloudAccounts());
  }, []);

  useEffect(() => {
    const { name, description, teamId, cloudId, tags, enabled } = resourceProfile;
    const tagsWithOutIds = tags?.map(({ _id, ...rest }) => rest);
    form.setFieldsValue({
      name,
      description,
      teamId,
      cloudId,
      enabled,
      tags: tagsWithOutIds,
    });
  }, [isLoadingResource]);

  return (
    <Form
      hideRequiredMark
      form={form}
      name="createMembershipForm"
      layout="vertical"
      onFinish={onFormFinish}
      validateMessages={UI_VALIDATION}
    >
      <Row className="fields-row" gutter={20} type="flex" justify="space-between">
        <ResourceField inputsData={updateResourceFields} span={24} xs={24} sm={12} lg={12} />
        <Row
          style={{
            display: "flex",
            width: "100%",
            margin: "14px",
          }}
        >
          <Col span={12}>
            Created At:
            {resourceProfile.createdAt &&
              moment(resourceProfile.createdAt).format("D MMMM YYYY h:mm A")}
          </Col>
          <Col span={12}>
            Updated At:
            {resourceProfile.updatedAt
              ? moment(resourceProfile.updatedAt).format("D MMMM YYYY h:mm A")
              : "N/A"}
          </Col>
        </Row>
        {/* TODO: add this into dynamic actions, if needed */}
        <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
          <Button
            type="primary"
            additionalProps={{
              htmlType: "submit",
            }}
            text={UI_TEXT.CTA.UPDATE}
          />
        </Col>
      </Row>
    </Form>
  );
};
export default UpdateResource;
