import { createSlice } from "@reduxjs/toolkit";
import { toast } from "utils";
import {
  getAllRecommendations,
  createRecommendation,
  createRecommendationActivity,
  deleteRecommendation,
  updateRecommendation,
  getRecommendation,
} from "redux/action";
import { getRecommendationsStats } from "redux/action/RecommendationActions";

const initialRecommendationState = {
  isLoading: false,
  isLoadingActivity: false,
  isLoadingRecommendation: false,
  isDeleteRecommendation: false,
  recommendationProfile: {},
  recommendationData: [],
  errorMessage: {},
  successMessage: "",
  recommendationStats: {},
  isLoadingRecommendationStats: false,
};

const RecommendationReducer = createSlice({
  name: "recommendation",
  initialState: initialRecommendationState,
  reducers: {
    removeRecommendation: (state, action) => {
      state.recommendationData.splice(
        state.recommendationData.findIndex((team) => team._id === action.payload),
        1
      );
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllRecommendations.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.recommendationData = payload?.data;
    });
    builder.addCase(getRecommendationsStats.fulfilled, (state, { payload }) => {
      state.recommendationStats = payload?.data;
      state.isLoadingRecommendationStats = true;
    });
    builder.addCase(createRecommendation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast("success", "Recommendation successfully created");
    });
    builder.addCase(createRecommendationActivity.fulfilled, (state, { payload }) => {
      state.isLoadingRecommendation = false;
      toast("success", "Action dispatched successfully");
    });
    builder.addCase(updateRecommendation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast("success", "Recommendation successfully updated");
    });
    builder.addCase(deleteRecommendation.fulfilled, (state, { payload }) => {
      state.isDeleteRecommendation = true;
      toast("success", payload.message);
    });
    builder.addCase(getRecommendation.fulfilled, (state, { payload }) => {
      state.isLoadingRecommendation = true;
      state.recommendationProfile = payload?.data;
    });
    // Pending
    builder.addCase(getAllRecommendations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRecommendation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRecommendationActivity.pending, (state, action) => {
      state.isLoadingActivity = true;
    });
    builder.addCase(updateRecommendation.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRecommendation.pending, (state, action) => {
      state.isDeleteRecommendation = false;
    });
    builder.addCase(getRecommendation.pending, (state, action) => {
      state.isLoadingRecommendation = false;
    });
    builder.addCase(getRecommendationsStats.pending, (state, action) => {
      state.isLoadingRecommendationStats = false;
    });
    // rejected
    builder.addCase(getAllRecommendations.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(createRecommendation.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(createRecommendationActivity.rejected, (state, action) => {
      state.isLoadingActivity = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(updateRecommendation.rejected, (state, action) => {
      state.isLoading = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(deleteRecommendation.rejected, (state, action) => {
      state.isDeleteRecommendation = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(getRecommendation.rejected, (state, action) => {
      state.isLoadingRecommendation = false;
      toast("error", action?.payload?.data?.message);
    });
    builder.addCase(getRecommendationsStats.rejected, (state, action) => {
      state.isLoadingRecommendationStats = false;
      toast("error", action?.payload?.data?.message);
    });
  },
});

const { actions } = RecommendationReducer;
export const { removeRecommendation } = actions;
export default RecommendationReducer.reducer;
