import { notification } from "components";

const toast = (type, description, title, obj = {}, onClick = null) => {
  const object = obj;
  object.key = Date.now();

  if (type === "success") {
    object.message = title || "Success";
  } else if (type === "info") {
    object.message = title || "Info";
  } else if (type === "warning") {
    object.message = title || "Warning";
  } else if (type === "error") {
    object.message = title || "Error";
  }

  const style = {
    width: object.Width || "100",
    marginLeft: object.marginLeft || "none",
    opacity: object.opacity || 0.91,
  };
  object.style = style;
  object.description = description || object.description;

  object.onClick = () => {
    if (onClick) onClick();
    notification.close(object.key);
  };

  return notification[type]({
    ...object,
  });
};

export default toast;
