import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllRecommendations = createAsyncThunk(
  "recommendations/getAll",
  async (req, { rejectWithValue }) => {
    const options = {
      url: req ? `${API_URL.ALL_RECOMMENDATIONS}?status=${req}` : API_URL.ALL_RECOMMENDATIONS,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getRecommendationsStats = createAsyncThunk(
  "recommendations/getRecommendationsStats",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.ALL_RECOMMENDATIONS}/statistics`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const createRecommendation = createAsyncThunk(
  "recommendation/create",
  async (params, { rejectWithValue }) => {
    const { fieldsValue } = params;
    const options = {
      url: API_URL.RECOMMENDATION,
      method: "POST",
      isAuth: true,
      data: {
        ...fieldsValue,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

// Unused method
const createRecommendationActivity = createAsyncThunk(
  "recommendationActivity/create",
  async (params, { rejectWithValue }) => {
    const { fieldsValue } = params;
    const options = {
      url: API_URL.RECOMMENDATION_ACTIVITY,
      method: "POST",
      isAuth: true,
      data: {
        ...fieldsValue,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteRecommendation = createAsyncThunk(
  "recommendation/delete",
  async (params, { rejectWithValue }) => {
    const { id } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION}/${id}`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const updateRecommendation = createAsyncThunk(
  "recommendation/udpate",
  async (params, { rejectWithValue }) => {
    const { fieldsValue, id } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION}/${id}`,
      method: "PUT",
      isAuth: true,
      data: {
        ...fieldsValue,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getRecommendation = createAsyncThunk(
  "recommendation/getRecommendation",
  async (params, { rejectWithValue }) => {
    const { id } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION}/${id}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllRecommendations,
  getRecommendationsStats,
  createRecommendation,
  createRecommendationActivity,
  deleteRecommendation,
  updateRecommendation,
  getRecommendation,
};
