import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllMetricsAggregators = createAsyncThunk(
  "metricsAggregators/all",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.METRICS_AGGREGATORS}?page=${params.page}&limit=${params.limit}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getMetricsAggregatorsById = createAsyncThunk(
  "metricsAggregators/id",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.METRICS_AGGREGATORS}/${params.id}?page=${params.page}&limit=${params.limit}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getMetricsAggregatorsFilter = createAsyncThunk(
  "metricsAggregators/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.METRICS_AGGREGATORS}?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const scanMetricsAggregators = createAsyncThunk(
  "metricsAggregators/scan",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.METRICS_AGGREGATORS}/pull?page=${params.page}&limit=${params.limit}`,
      method: "POST",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteAllMetricsAggregators = createAsyncThunk(
  "metricsAggregators/deleteAll",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.METRICS_AGGREGATORS}/deleteAll`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllMetricsAggregators,
  getMetricsAggregatorsById,
  scanMetricsAggregators,
  deleteAllMetricsAggregators,
  getMetricsAggregatorsFilter,
};
