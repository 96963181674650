import { Card, Col, Tag } from "antd";
import { afterDecimalFourDigit } from "utils/helper";

const CardItem = ({ item }) => {
  const { ccoeeMetricType, mean, median, mode, variance, standardDeviation, maximum, minimum } =
    item;
  return (
    <Col span={6} xs={24} sm={12} lg={6}>
      <Card
        size="small"
        title={ccoeeMetricType}
        style={{
          width: 300,
          maxHeight: 235,
          marginBottom:8,
        }}
      >
        <Tag className="mv-2">
          <b>Mean</b>:{afterDecimalFourDigit(mean)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>Median</b>:{afterDecimalFourDigit(median)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>Mode</b>:{afterDecimalFourDigit(mode)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>Variance</b>:{afterDecimalFourDigit(variance)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>SD</b>:{afterDecimalFourDigit(standardDeviation)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>Maximum</b>:{afterDecimalFourDigit(maximum)}
        </Tag><br/>
        <Tag className="mv-2">
          <b>Minimum</b>:{afterDecimalFourDigit(minimum)}
        </Tag>
      </Card>
    </Col>
  );
};

export default CardItem;
