import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  DatePicker,
  Form,
  Button,
  Space,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreditCardOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { getAllBillingReports, getBillingReports, getAllResources } from "redux/action";
import { afterDecimalTwoDigit } from "utils/helper";
import { usePermission } from "hooks";
import roles from "config/roles";
import moment from "moment";

const filterFields = [
  {
    id: 1,
    name: "name",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "minCost",
    label: "Minimum Cost",
    placeholder: "1",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 4,
    name: "maxCost",
    label: "Maximum Cost",
    placeholder: "1",
    type: "Text",
    rules: [{ required: false }],
  },
];

const Billing = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const { isLoading, billing } = useSelector((state) => state.reports);
  const { billingRes, count } = billing;

  const columns = [
    {
      title: "Project Id",
      dataIndex: "project_id",
      key: "project_id",
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resource",
    },
    {
      title: "Team",
      dataIndex: "teamName",
      key: "team",
      hidden: usePermission([roles.MANAGER]),
    },
    {
      title: "Billing Month",
      dataIndex: "billing_date",
      key: "usage_end",
    },
    {
      title: "Cost (USD)",
      dataIndex: "cost",
      key: "cost",
      render: (_, record) => (
        <span>{`${afterDecimalTwoDigit(record?.aggregate_cost)} ${record.cost_unit}`}</span>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getReport();
  }, []);

  const onFormFinish = ({ name, month, projectId, minCost, maxCost }) => {
    if (!name && !projectId && !month && !minCost && !maxCost) return;

    setSearchData({});
    const data = {
      month: month ? moment(month).format("YYYY-MM").toUpperCase() : null,
      name: name,
      projectId: projectId,
      minCost: minCost,
      maxCost: maxCost,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }

    dispatch(getBillingReports(data));
    setSearchData(data);
    setIsFilter(true);
    // form.resetFields();
  };

  const getReport = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllBillingReports({ page: 1}));
  };

  const onPageChange = (pageNum) =>
    isFilter ? dispatch(getBillingReports({ page: pageNum, ...searchData })) :  dispatch(getAllBillingReports({ page: pageNum }));

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CreditCardOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2">Billing Information </span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={4} xs={4} sm={4} lg={4} />
          <Col span={8} xs={8} sm={8} lg={8} className="flex ">
            <DatePicker name="month" label="Select Month" type="month" />
          </Col>

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getReport}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={billingRes}
          isLoading={isLoading}
          rowKey="team"
          pagination={{ total: count, onChange: (pageNum) => onPageChange(pageNum) }}
        />
      </Layout>
    </Layout>
  );
};

export default Billing;
