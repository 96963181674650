import { TeamOutlined } from "@ant-design/icons";
import { DynamicField as TeamField, Form, Row, Col, Button } from "components";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeam, updateTeam } from "redux/action";

const updateTeamFields = [
  {
    id: 1,
    name: "name",
    label: "Name",
    placeholder: "Name",
    type: "Text",
    subType: "Text",
    rules: [{ required: true }],
  },
  {
    id: 2,
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Text",
    subType: "TextArea",
    additionalProps: {
      maxLength: 100,
      rows: 4,
    },
    rows: 4,
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "enabled",
    label: "Status",
    type: "Switch",
    additionalProps: {
      checkedChildren: "Enabled",
      unCheckedChildren: "Disabled",
    },
  },
];

const UpdateTeam = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoadingTeam, teamProfile } = useSelector((state) => state.team);

  const [form] = Form.useForm();
  const onFormFinish = (fieldsValue) => {
    dispatch(updateTeam({ fieldsValue, id }));
  };

  useEffect(() => {
    dispatch(getTeam({ id }));
  }, []);

  useEffect(() => {
    const { name, description, enabled } = teamProfile;
    form.setFieldsValue({
      name,
      description,
      enabled,
    });
  }, [isLoadingTeam]);

  return (
    <div>
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <TeamOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">Update Team</span>
      </Col>
      <div className="mt-4">
        <Form
          hideRequiredMark
          form={form}
          name="createMembershipForm"
          layout="vertical"
          onFinish={onFormFinish}
          validateMessages={UI_VALIDATION}
        >
          <Row className="fields-row" gutter={20} type="flex" justify="space-between">
            <TeamField inputsData={updateTeamFields} span={12} xs={24} sm={12} lg={12} />

            {/* TODO: add this into dynamic actions, if needed */}
            <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={UI_TEXT.CTA.UPDATE}
              />
            </Col>

            {/* TODO: Add User Table Here */}
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default UpdateTeam;
