import {
  Table,
  Layout,
  Col,
  Row,
  Form,
  Space,
  RangePicker,
  Button,
  DynamicField as FilterField,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreditCardOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { getAllBillingPerTeamReports, getFilterBillingPerTeamReports } from "redux/action";
import moment from "moment";
import { afterDecimalTwoDigit } from "utils/helper";

const filterFields = [
  {
    id: 2,
    name: "resource",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
];

const dateFormat = (date) => moment(date).format("DD-MMM-YYYY");

const Billing_Per_Team = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const { isLoading, billingPerTeamReports } = useSelector((state) => state.reports);
  const { billingPerTeamReport, totalPages, currentPage, count } = billingPerTeamReports;

  const columns = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      width: 250,
      render: (_, { team }) => <span>{team == "Orphan" ? "UNKNOWN" : team}</span>,
    },
    {
      title: "Resource",
      dataIndex: "resource",
      key: "resource",
      width: 250,
      render: (_, record) => <span>{`${record.resource.split("/").at(-1)}`}</span>,
    },
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
      width: 250,
    },
    {
      title: "Total Recommendations",
      dataIndex: "totalRecommendation",
      key: "totalRecommendation",
      width: 250,
    },
    {
      title: "Billing (USD)",
      dataIndex: "totalBilling",
      key: "totalBilling",
      render: (_, record) => <span>{`${afterDecimalTwoDigit(record.totalBilling)}`}</span>,
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getAllBillingPerTeamReport();
  }, []);

  const onFormFinish = ({ team, resource, month, projectId }) => {
    // const fromDate = new Date(month[0]._d);
    // fromDate.setHours(0, 0, 0, 0);
    // const toDate = new Date(month[1]._d);
    // toDate.setHours(0, 0, 0, 0);
    if (!resource && !projectId) return;
    setSearchData({});
    const data = {
      resource,
      projectId,
      // toDate,
      // fromDate,
    };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getFilterBillingPerTeamReports(data));
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getFilterBillingPerTeamReports({ page: pageNum, ...searchData }))
      : dispatch(getAllBillingPerTeamReports({ page: pageNum }));
  };

  const getAllBillingPerTeamReport = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllBillingPerTeamReports({ page: 1 }));
  };

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CreditCardOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2">Billing Per Team </span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={12} xs={12} sm={12} lg={12} />
          {/* <Col span={8} xs={8} sm={8} lg={8} className="flex ">
            <RangePicker
              name="month"
              onChange={(date, dateString) => {
                return date, dateString;
              }}
              label="Select Month"
              // disabled={true}
            />
          </Col> */}
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllBillingPerTeamReport}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={billingPerTeamReport}
          isLoading={isLoading}
          rowKey="team"
          pagination={{ total: count, onChange: (pageNum) => onPageChange(pageNum) }}
        />
      </Layout>
    </Layout>
  );
};

export default Billing_Per_Team;
