const UI_TEXT = {
  COMMON: {
    ALL_RIGHT_RESERVED: "Copyright © 2023 Nisum, Inc. All rights reserved.",
  },
  CTA: {
    LOGIN: "Login",
    CREATE_MEMBERSHIP: "Create Membership",
    CREATE_USER: "Create User",
    UPDATE: "Update",
    SEARCH: "Search",
    CLEAR: "Clear",
    RESET_PASSWORD: "Reset Password",
    CHANGE_PASSWORD: "Change Password",
  },
  TEAM: {
    CREATE_TEAM: "Create Team",
  },
  CLOUD_ACCOUNT: {
    CREATE_PROFILE: "Create Profile",
  },
};
export default UI_TEXT;
