import { Space } from "antd";
import { DynamicField as ResourceField, Form, Row, Col, Button } from "components";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRecommendation, updateRecommendation } from "redux/action";
import ViewAllMetrices from "./ViewAllMetrices";
import ViewAllRecommendationActions from "./ViewAllRecommendationActions";

const UpdateRecommendation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoadingRecommendation, recommendationProfile } = useSelector(
    (state) => state.recommendation
  );
  const [actionButtons, showActionButtons] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [form] = Form.useForm();

  const recommendationFields = [
    {
      id: 1,
      name: "resourceName",
      label: "Resource",
      type: "Text",
      subType: "Text",
      disabled: true,
    },
    {
      id: 2,
      name: "cloudName",
      label: "Cloud",
      type: "Text",
      subType: "Text",
      disabled: true,
    },
    {
      id: 3,
      name: "reasons",
      label: "Reasons",
      type: "Text",
      subType: "TextArea",
      disabled: true,
    },
  ];

  useEffect(() => {
    dispatch(getRecommendation({ id }));
  }, []);

  useEffect(() => {
    const { resourceName, cloudName, reasons, recommendations, status } = recommendationProfile;
    showActionButtons(status);
    form.setFieldsValue({
      resourceName,
      cloudName,
      reasons,
      recommendations,
    });
  }, [isLoadingRecommendation]);

  const onClick = (e) => {
    const { resourceId, cloudId, reasons, metrics, recommendations, activities } =
      recommendationProfile;
    const recommendationsWithOutIds = recommendations?.map(({ _id, ...rest }) => rest);
    const metricsWithOutIds = metrics?.map(({ _id, timestamps, ...rest }) => rest);
    const activitiesWithOutIds = activities?.map(({ _id, timestamps, ...rest }) => rest);
    const fieldsValue = {
      resourceId,
      cloudId,
      reasons,
      metrics: metricsWithOutIds,
      recommendations: recommendationsWithOutIds,
      status: e.target?.textContent,
      activities: [
        ...activitiesWithOutIds,
        {
          type: e.target?.textContent,
          userId: user.employee_id,
        },
      ],
    };
    dispatch(updateRecommendation({ fieldsValue, id }));
  };

  return (
    <>
      {actionButtons === "Open" && (
        <Row className="flex justify-end">
          <Space>
            <Button text="Execute" onClick={onClick} />
            <Button text="Decline" onClick={onClick} type="danger" />
          </Space>
        </Row>
      )}
      {actionButtons === "Decline" && (
        <Row className="flex justify-end">
          <Space>
            <Button text="Open" onClick={onClick} />
          </Space>
        </Row>
      )}
      <Form
        hideRequiredMark
        form={form}
        name="createMembershipForm"
        layout="vertical"
        validateMessages={UI_VALIDATION}
      >
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <ResourceField inputsData={recommendationFields} span={24} xs={24} sm={12} lg={12} />
        </Row>
      </Form>

      <h4>Metrics</h4>
      <ViewAllMetrices metricsData={recommendationProfile?.metrics} />

      <h4>Recommendation Actions</h4>
      <ViewAllRecommendationActions
        recommendationActions={recommendationProfile?.recommendations}
      />
    </>
  );
};
export default UpdateRecommendation;
