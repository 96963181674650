import { Table, Layout, Col, Space, Tag } from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, deleteUser } from "redux/action";
// import { Space, Tag } from "antd";
import { clearUserProfile, removeUser } from "redux/reducers/UserReducer";
import { Link } from "react-router-dom";
import { TeamOutlined } from "@ant-design/icons";
// TODO: needs to adjust in to the fixtures files

const ViewAllUser = () => {
  const columns = [
    {
      title: "Employee Id",
      dataIndex: "_id",
      key: "id",
      //defaultSortOrder: "descend",
      //sorter: (a, b) => a.employee_id - b.employee_id,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "Team",
      dataIndex: "teamName",
      key: "teamName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Cell#",
      dataIndex: "cellNumber",
      key: "cellNumber",
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (_, record) => (
        <Tag color={record.enabled ? "green" : "pink"}>
          {record.enabled ? "ENABLED" : "DISABLED"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/user/updateUser/${record._id}`} data-testid="edit">
            Edit
          </Link>
          <a
            data-testid="delete"
            onClick={(e) => {
              deleteRecord(record._id, e);
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { isLoading, userData, isDeleteUser } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const [employeeId, setEmployeeId] = useState(null);

  useEffect(() => {
    dispatch(getAllUsers(user.role));
    dispatch(clearUserProfile());
  }, []);

  useEffect(() => {
    if (isDeleteUser) {
      dispatch(removeUser(employeeId));
    }
  }, [isDeleteUser]);

  const deleteRecord = (id, e) => {
    e.preventDefault();
    setEmployeeId(id);
    dispatch(deleteUser({ id }));
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <TeamOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">View All User</span>
      </Col>
      <Layout className="mt-4 bg-transparent">
        <Table columns={columns} dataSource={userData} isLoading={isLoading} rowKey="employee_id" />
      </Layout>
    </Layout>
  );
};

export default ViewAllUser;
