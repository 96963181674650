import {
  Table,
  Layout,
  Tag,
  Col,
  Row,
  DynamicField as FilterField,
  DatePicker,
  Form,
  Button,
  Space,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import {
  deleteAllRecommendationExecutor,
  getAllRecommendationExecutor,
  getRecommendationExecutorFilter,
  scanRecommendationExecutor,
} from "redux/action";
import moment from "moment";
import { NavLink } from "react-router-dom";
// TODO: needs to adjust in to the fixtures files

const filterFields = [
  {
    id: 1,
    name: "title",
    label: "Title",
    placeholder: "Title",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "recommendationId",
    label: "Recommendation Id",
    placeholder: "Recommendation Id",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "projectId",
    label: "project Id",
    placeholder: "projectId",
    type: "Text",
    rules: [{ required: false }],
  },
];

const RecommendationExecutor = () => {
  const limit = 50;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const { isLoading, recommendationExecutorsData, total, pageSize } = useSelector(
    (state) => state.recommendationExecutor
  );

  const { recommendationExecutorData, totalPages, currentPage, count } =
    recommendationExecutorsData;

  const tagColor = {
    AUTHORIZE: "success",
    CLOSED: "success",
    NEW: "success",
    CANCELLED: "error",
    SCHEDULED: "warning",
    REVIEW: "lime",
    IMPLEMENT: "processing",
  };

  const columns = [
    {
      title: "Ticket #",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      width: 100,
    },
    {
      title: "Recommendation Id",
      dataIndex: "recommendationId",
      width: 100,
      key: "recommendationId",
      // render: () => <Tag color="blue">Teamweb</Tag>,
    },
    {
      title: "project Id",
      dataIndex: "projectId",
      width: 100,
      key: "projectId",
      render: (text, value) => {
        return (
          <NavLink to={`/dashboard/resourcemanagment/resources/?projectId=${text}`}>
            <div>{text}</div>
          </NavLink>
        );
      },
      // render: () => <Tag color="blue">Teamweb</Tag>,
    },
    {
      title: "Assign to",
      dataIndex: "teamName",
      width: 100,
      key: "assign_to",
      // render: () => <Tag color="blue">Teamweb</Tag>,
    },
    {
      title: "Title",
      dataIndex: "serviceNowShortDescription",
      key: "serviceNowShortDescription",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "status",
      width: 70,
      render: (data) => <Tag color={tagColor[`${data.toUpperCase()}`]}>{data}</Tag>,
    },
    {
      title: "Description",
      dataIndex: "serviceNowDescription",
      key: "serviceNowDescription",
      width: 175,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
    },
  ];

  useEffect(() => {
    // dispatch(
    //   getAllRecommendationExecutor({
    //     page: 1,
    //     limit,
    //   })
    // );
    getAllChangeManagment();
  }, []);

  const onFormFinish = ({ ticket, month, title, projectId, recommendationId }) => {
    if (!ticket && !title && !month && !projectId && !recommendationId) return;

    setSearchData({});
    const data = {
      month: month ? moment(month).format("YYYY-MM").toUpperCase() : null,
      ticket: ticket,
      projectId: projectId,
      title: title,
      recommendationId: recommendationId,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }

    dispatch(getRecommendationExecutorFilter(data)); // TODO Filter
    setSearchData(data);
    setIsFilter(true);
    // form.resetFields();
  };

  const getAllChangeManagment = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(
      getAllRecommendationExecutor({
        page: 1,
        limit,
      })
    );
  };

  const onPageChange = (pageNum) =>
    isFilter
      ? dispatch(getRecommendationExecutorFilter({ page: pageNum, ...searchData }))
      : getAllRecommendationExecutor({ page: pageNum });

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <CheckCircleOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2"> Change Managment</span>
      </Col>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={8} xs={8} sm={8} lg={8} />
          {/* <Col span={6} xs={6} sm={6} lg={6} className="flex ">
            <DatePicker name="month" label="Select Month" type="month" />
          </Col> */}

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllChangeManagment}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={recommendationExecutorData}
          pagination={{ total: count, onChange: (e) => onPageChange(e) }}
          isLoading={isLoading}
          rowKey="_id"
        />
      </Layout>
    </Layout>
  );
};

export default RecommendationExecutor;
