import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllCloudAccounts = createAsyncThunk(
  "cloudaccount/getAll",
  async (_, { rejectWithValue }) => {
    const options = {
      url: API_URL.ALL_CLOUD_ACCOUNTS,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
const getCloudAccountFilters = createAsyncThunk(
  "cloudaccount/getFilter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.ALL_CLOUD_ACCOUNTS}?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getCloudsStats = createAsyncThunk(
  "cloudaccount/getCloudsStats",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.ALL_CLOUD_ACCOUNTS}/statistics`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const createCloudAccount = createAsyncThunk(
  "cloudaccount/create",
  async (params, { rejectWithValue }) => {
    const { fieldsValue } = params;
    const options = {
      url: API_URL.CLOUD_ACCOUNT,
      method: "POST",
      isAuth: true,
      data: {
        ...fieldsValue,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteCloudAccount = createAsyncThunk(
  "cloudaccount/delete",
  async (params, { rejectWithValue }) => {
    const { id } = params;
    const options = {
      url: `${API_URL.CLOUD_ACCOUNT}/${id}`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const updateCloudAccount = createAsyncThunk(
  "cloudaccount/udpate",
  async (params, { rejectWithValue }) => {
    const { fieldsValue, id } = params;
    const options = {
      url: `${API_URL.CLOUD_ACCOUNT}/${id}`,
      method: "PUT",
      isAuth: true,
      data: {
        ...fieldsValue,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getCloudAccount = createAsyncThunk(
  "cloudaccount/getCloudAccount",
  async (params, { rejectWithValue }) => {
    const { id } = params;
    const options = {
      url: `${API_URL.CLOUD_ACCOUNT}/${id}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllCloudAccounts,
  getCloudsStats,
  createCloudAccount,
  deleteCloudAccount,
  updateCloudAccount,
  getCloudAccount,
  getCloudAccountFilters,
};
