const BASE_ROUTE = "/";
const ROUTES = {
  ADMIN: BASE_ROUTE,
  DASHBOARD: "dashboard",
  // CREATE_MEMBERSHIP: "createmembership",
  // SEARCH_MEMBERSHIP: "searchmembership",
  CREATE_USER: "user/createUser",
  UPDATE_USER: "user/updateUser/:id",
  ALL_USERS: "user/viewAllUser",
  ABOUT: "about",
  USER_MANUAL: "usermanual",
  RESET_PASSWORD: "reset-password",
  ALL_TEAMS: "team/viewAllTeams",
  ALL_GROUPS: "group/viewAllGroups",
  CREATE_GROUP: "group/createGroup",
  CREATE_TEAM: "team/createTeam",
  UPDATE_TEAM: "team/updateTeam/:id",
  VIEW_CLOUDS: "cloud/viewAllClouds",
  ADD_CLOUD_PROFILE: "cloud/addProfile",
  UPDATE_CLOUD_PROFILE: "cloud/updateProfile/:id",
  RESOURCE_MANAGEMENT: "resourcemanagment/resources",
  METRICS_USABILITY: "resourcemanagment/usability-metrics",
  METRICS_USABILITY_RESOURCE: "resourcemanagment/usability-metrics/:id",
  UPDATE_RESOURCE: "resourcemanagment/updateResource/:id",
  REPORT: "report",
  HISTORY: "history",
  RECOMMENDATIONS: "recommendations",
  ACTIVE_RECOMMENDATIONS: "recommendations/active",
  LAUNCHED_RECOMMENDATIONS: "recommendations/launched",
  RECOMMENDATION_DETAIL: "recommendations/detail/:id",
  METRICS_ACQUIRER: "metrics/acquirer",
  METRICS_ANALYSIS: "metrics/analysis",
  RECOMMENDATION_MANAGMENT: "recommendations-managment",
  CHANGE_MANAGMENT: "change-managment",
  BILLING: "resourcemanagment/billing",
  REPORT_RECOMMENDATIONS_SUMMARY: "report/recommendations_summary",
  REPORT_RECOMMENDATIONS_DETAILS: "report/recommendations_details",
  REPORT_BILLING_PER_TEAM: "report/billing_per_team",
  REPORT_UNASSIGNED_RESOURCES: "report/unassigned_resources",
  VIEW_PROJECT: "project",
  MORE_INFORMATION: "metrics/moreinformation",
};
export default ROUTES;
