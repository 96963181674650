import { DynamicField as UserField, Form, Row, Col, Button, Layout } from "components";
import roles from "config/roles";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeams } from "redux/action";
import { createUser } from "redux/action/UserAction";
import { UserAddOutlined } from "@ant-design/icons";
import {
  numberValidation,
  organizationValidEmail,
  customValidPassword,
  customPhoneNumber,
} from "utils";

// TODO: needs to adjust in to the fixtures files

const CreateUser = () => {
  const dispatch = useDispatch();
  const { teamData } = useSelector((state) => state.team);
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState(0);

  const handleChange = (value) => {
    setSelectedRole(value);
  };

  const commonUserFields = [
    //   {
    //   id: 1,
    //   name: "employee_id",
    //   label: "Employee ID",
    //   placeholder: "Employee ID",
    //   type: "Text",
    //   subType: "Text",
    //   //rules: [{ required: true }, numberValidation],
    // },
    {
      id: 2,
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      type: "Text",
      subType: "Text",
      rules: [{ required: true }],
    },
    {
      id: 3,
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      type: "Text",
      subType: "Text",
      rules: [{ required: true }],
    },
    {
      id: 4,
      name: "alias",
      label: "Alias Name",
      placeholder: "Alias Name",
      type: "Text",
      subType: "Text",
      rules: [{ required: true }],
    },
    {
      id: 5,
      name: "gender",
      label: "Gender",
      placeholder: "Gender",
      type: "Select",
      rules: [{ required: true }],
      optType: "Option",
      option: [
        {
          value: "MALE",
          title: "Male",
        },
        {
          value: "FEMALE",
          title: "Female",
        },
      ],
    },
    {
      id: 6,
      name: "email",
      label: "Email",
      placeholder: "xyz@nisum.com",
      type: "Text",
      subType: "Text",
      rules: [{ required: true, type: "email" }, organizationValidEmail],
    },
    {
      id: 7,
      name: "password",
      label: "Password",
      placeholder: "*******",
      type: "Text",
      subType: "Password",
      rules: [{ required: true }, customValidPassword],
    },
    {
      id: 8,
      name: "role",
      label: "Role",
      placeholder: "Role",
      type: "Select",
      rules: [{ required: true }],
      optType: "Option",
      additionalProps: {
        onChange: handleChange,
      },
      option: [
        {
          value: "1",
          isDisabled: true,
          title: "Super Admin",
        },
        {
          value: "2",
          title: "Admin",
        },
        {
          value: "3",
          title: "Team Member",
        },
        {
          value: "4",
          title: "Manager",
        },
      ],
    },
  ];

  const userFields = [
    ...commonUserFields,
    ...[
      {
        id: 10,
        name: "cellNumber",
        label: "Cell Number",
        placeholder: "Cell Number",
        type: "Text",
        subType: "Text",
        rules: [{ required: false }, customPhoneNumber],
      },
      {
        id: 11,
        name: "enabled",
        label: "Status",
        type: "Switch",
        additionalProps: {
          checkedChildren: "Enabled",
          unCheckedChildren: "Disabled",
        },
        defaultChecked: true,
        rules: [{ required: false }],
      },
    ],
  ];

  const teamUserFields = [
    ...commonUserFields,
    ...[
      {
        id: 9,
        name: "teamId",
        label: "Team",
        placeholder: "Team",
        type: "Select",
        rules: [{ required: true }],
        option: (teamData || []).map((d) => ({
          value: d._id,
          title: d.name,
        })),
      },
      {
        id: 10,
        name: "cellNumber",
        label: "Cell Number",
        placeholder: "Cell Number",
        type: "Text",
        subType: "Text",
        rules: [{ required: false }, customPhoneNumber],
      },
      {
        id: 11,
        name: "enabled",
        label: "Status",
        type: "Switch",
        additionalProps: {
          checkedChildren: "Enabled",
          unCheckedChildren: "Disabled",
        },
        defaultChecked: true,
        rules: [{ required: false }],
      },
    ],
  ];

  useEffect(() => {
    dispatch(getAllTeams());
  }, []);

  const onFormFinish = (fieldsValue) => {
    dispatch(createUser({ fieldsValue }));
    form.resetFields();
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <UserAddOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">View All User</span>
      </Col>
      <Layout className="mt-4 bg-transparent">
        <Form
          hideRequiredMark
          form={form}
          name="createMembershipForm"
          layout="vertical"
          onFinish={onFormFinish}
          validateMessages={UI_VALIDATION}
        >
          <Row className="fields-row" gutter={20} type="flex" justify="space-between">
            <UserField
              inputsData={Number(selectedRole) !== roles.TEAM_MEMBER ? userFields : teamUserFields}
              span={12}
              xs={24}
              sm={12}
              lg={12}
            />
            {/* TODO: add this into dynamic actions, if needed */}
            <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={UI_TEXT.CTA.CREATE_USER}
              />
            </Col>
          </Row>
        </Form>
      </Layout>
    </Layout>
  );
};
export default CreateUser;
