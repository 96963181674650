import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllProject = createAsyncThunk("projects/all", async (params, { rejectWithValue }) => {
  const { page = 1 } = params;
  const options = {
    url: `${API_URL.ALL_PROJECTS}?page=${page}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getProjectFilter = createAsyncThunk("projects/filters", async (params, { rejectWithValue }) => {
  const { page = 1 } = params;
  const options = {
    url: `${API_URL.ALL_PROJECTS}?page=${page}`,
    method: "POST",
    isAuth: true,
    data: {
      ...params,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { getAllProject, getProjectFilter };
