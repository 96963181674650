import { Input, Select, Col, Switch } from "components/atoms";
import { PropTypes } from "prop-types";
import DynamicList from "../DynamicList";

const DynamicField = ({ inputsData, name, span, xs, sm, lg }) =>
  inputsData.map((item) => {
    return (
      <Col data-testid="field-layout" key={item?.id} span={span} xs={xs} sm={sm} lg={lg}>
        {item?.type === "Text" ? <TextInput item={item} name={name} /> : null}
        {item?.type === "Select" ? <SelectInput item={item} /> : null}
        {item?.type === "Switch" ? <SwitchInput item={item} /> : null}
        {item?.type === "List" ? <DynamicList item={item} /> : null}
      </Col>
    );
  });

const TextInput = ({ item, name }) => (
  <Input
    placeholder={item?.placeholder}
    type={item?.type}
    subType={item?.subType}
    label={item?.label}
    disabled={item?.disabled}
    name={item.isTypeList ? [name, item.name] : item.name}
    validationRules={item?.rules}
    rows={item?.rows}
    additionalProps={item?.additionalProps}
  />
);
const SelectInput = ({ item }) => (
  <Select
    placeholder={item?.placeholder}
    type={item?.type}
    label={item?.label}
    name={item?.name}
    disabled={item?.disabled}
    option={item?.option}
    validationRules={item?.rules}
    additionalProps={item?.additionalProps}
  />
);
const SwitchInput = ({ item }) => (
  <Switch
    label={item?.label}
    name={item?.name}
    defaultChecked={item?.defaultChecked}
    additionalProps={item?.additionalProps}
  />
);

DynamicField.defaultProps = {
  span: 24,
  xs: 24,
  sm: 24,
  lg: 24,
};

DynamicField.propTypes = {
  inputsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  span: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  lg: PropTypes.number,
};
export default DynamicField;
