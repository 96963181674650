import { Button as AntButton } from "antd";
import noop from "lodash/noop";
import { PropTypes } from "prop-types";

const Button = ({ onClick, text, type, isLoading, icon, disabled, additionalProps }) => (
  <AntButton
    data-testid="atom-btn"
    type={type}
    onClick={onClick}
    loading={isLoading}
    icon={icon}
    disabled={disabled}
    {...additionalProps}
  >
    {text}
  </AntButton>
);
Button.defaultProps = {
  onClick: noop,
  text: "no text",
  type: "primary",
  isLoading: false,
  disabled: false,
  additionalProps: {},
};
Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalProps: PropTypes.shape({}),
};
export default Button;
