import { Table, Layout, Col, Row } from "components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import { FundOutlined } from "@ant-design/icons";
import { getAllMetricTypes } from "redux/action";
import { afterDecimalFourDigit, bytesToKB } from "utils/helper";
import { useParams } from "react-router-dom";
import { getMetricsByResource } from "redux/action/MetricsActions";
// TODO: needs to adjust in to the fixtures files

const UsabilityMetricsResource = () => {
  const { id } = useParams();

  const limit = 50;
  const dispatch = useDispatch();

  const { isLoading, usabilityMetricsData, totalUsabilityMetrics, pageSizeUsabilityMetrics } =
    useSelector((state) => state.metrics);
  const { metricTypesData } = useSelector((state) => state.metricTypes);

  const uniqueDate = () => {
    let unique = [...new Set(usabilityMetricsData.map((item) => item.date))];
    return unique.map((item) => ({ text: item, value: item }));
  };

  const uniqueResource = () => {
    let unique = [...new Set(usabilityMetricsData.map((item) => item.resourceName))];
    return unique.map((item) => ({ text: item, value: item }));
  };

  const filterMetricTypes = (metric) => {
    if (metric) {
      const { ccoeeMetricType, value } = metric;
      return (
        <>
          <Tag>
            <b>{ccoeeMetricType}</b>
          </Tag>
          <br />
        </>
      );
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      filters: uniqueDate(),
      filterSearch: true,
      onFilter: (value, record) => record.date.startsWith(value),
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resourceName",
      filters: uniqueResource(),
      filterSearch: true,
      onFilter: (value, record) => record.resourceName.startsWith(value),
    },
    {
      title: "Metrics",
      dataIndex: "metricsDetailList",
      key: "metricsDetailList",
      render: (metricsDetailList) => metricsDetailList?.map((metric) => filterMetricTypes(metric)),
    },
  ];

  useEffect(() => {
    dispatch(
      getMetricsByResource({
        id: id,
        page: 1,
        limit,
      })
    );
    dispatch(getAllMetricTypes());
  }, []);

  const onPageChange = (page) => {
    dispatch(
      getMetricsByResource({
        id: id,
        page,
        limit,
      })
    );
  };
  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <FundOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Usability Metrics</span>
        </Col>
      </Row>

      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={usabilityMetricsData}
          isLoading={isLoading}
          pagination={{
            total: totalUsabilityMetrics,
            pageSize: pageSizeUsabilityMetrics,
            onChange: (e) => onPageChange(e),
          }}
          rowKey="_id"
        />
      </Layout>
    </Layout>
  );
};

export default UsabilityMetricsResource;
