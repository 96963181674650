import { Form, DynamicField as ForgotField } from "components";
import { Modal } from "components/atoms";
import PropTypes from "prop-types";

const fieldData = [
  {
    id: 1,
    name: "email",
    label: "Email",
    placeholder: "abc@ccoe.com",
    type: "Text",
    subType: "Text",
    rules: [{ required: true, type: "email" }],
  },
];

const ForgotPassword = ({ title, isModalOpen, handleOk, handleCancel, confirmLoading }) => {
  const [form] = Form.useForm();

  const onCancel = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    handleOk(values);
    form.resetFields();
  };

  return isModalOpen ? (
    <Modal
      data-testid="modal-window"
      title={title}
      open={isModalOpen}
      onOk={form.submit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <Form data-testid="forgot-form" form={form} onFinish={onFinish}>
        <ForgotField inputsData={fieldData} span={24} xs={24} sm={24} lg={24} />
      </Form>
    </Modal>
  ) : null;
};
ForgotPassword.propTypes = {
  title: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ForgotPassword;
