import {
  HomeOutlined,
  UserOutlined,
  CloudServerOutlined,
  TeamOutlined,
  CloudOutlined,
  ApiOutlined,
  WarningOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  BulbOutlined,
  LineChartOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  RiseOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

export const manager = [
  {
    key: 1,
    label: "Home",
    route: "/",
    "data-testid": "home",
    icon: <HomeOutlined />,
  },
  {
    key: 2,
    label: "Cloud Accounts",
    route: "cloud/viewAllClouds",
    "data-testid": "cloud-account",
    icon: <CloudOutlined />,
  },
  {
    key: 3,
    label: "Team Management",
    route: "team/viewAllTeams",
    "data-testid": "teams",
    icon: <TeamOutlined />,
  },
  {
    key: 3.1,
    label: "Project Management",
    route: "project",
    "data-testid": "project",
    icon: <ProjectOutlined />,
    // disabled: true,
  },
  {
    key: 4,
    label: "Resource Management",
    route: "",
    "data-testid": "resource-managment",
    icon: <CloudServerOutlined />,
    children: [
      {
        key: 4.1,
        label: "Resources",
        route: "resourcemanagment/resources",
        "data-testid": "resource",
        icon: <CloudServerOutlined />,
      },
      // {
      //   "key": 4.2,
      //   "label": "Usability Metrics",
      //   "route": "resourcemanagment/usability-metrics",
      //   "data-testid": "usability-metrics",
      //   "icon":<FundOutlined />
      // },
      {
        key: 4.3,
        label: "Billing",
        route: "resourcemanagment/billing",
        "data-testid": "billing",
        icon: <CreditCardOutlined />,
      },
    ],
  },
  {
    key: 5,
    label: "Resource Utilization",
    route: "",
    "data-testid": "resourceUtilization",
    icon: <LineChartOutlined />,
    children: [
      {
        key: 5.1,
        label: "Details",
        route: "metrics/acquirer",
        "data-testid": "acquirer",
        icon: <FileTextOutlined />,
      },
      {
        key: 5.2,
        label: "Analysis",
        route: "metrics/analysis",
        "data-testid": "analysis",
        icon: <CopyOutlined />,
      },
    ],
  },
  {
    key: 6,
    label: "Recommendations",
    route: "recommendations-managment",
    "data-testid": "recommendations-managment",
    icon: <RiseOutlined />,
    // children: [
    //   {
    //     key: 6.1,
    //     label: "Generator",
    //     route: "recomendation/generator",
    //     "data-testid": "generator",
    //     icon: <RiseOutlined />,
    //   },
    //   {
    //     key: 6.2,
    //     label: "Executor",
    //     route: "recomendation/executor",
    //     "data-testid": "executor",
    //     icon: <CheckCircleOutlined />,
    //   },
    // ],
  },
  {
    key: 7,
    label: "Change Managment",
    route: "change-managment",
    "data-testid": "executor",
    icon: <CheckCircleOutlined />,
  },
  {
    key: 8,
    label: "History",
    route: "history",
    "data-testid": "history",
    // disabled: true,
    icon: <UnorderedListOutlined />,
  },
  {
    key: 9,
    label: "Reports",
    route: "report",
    "data-testid": "report",
    icon: <UnorderedListOutlined />,
    children: [
      {
        key: 9.1,
        label: "Recommendation Summary",
        route: "report/recommendations_summary",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
      {
        key: 9.2,
        label: "Recommendation Details",
        route: "report/recommendations_details",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
      {
        key: 9.3,
        label: "Billing Per Team",
        route: "report/billing_per_team",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
      {
        key: 9.4,
        label: "Unassigned Resources",
        route: "report/unassigned_resources",
        "data-testid": "report",
        icon: <CreditCardOutlined />,
      },
    ],
  },
  {
    key: 10,
    label: "Help",
    route: "",
    "data-testid": "help",
    icon: <ApiOutlined />,
    children: [
      {
        key: 10.1,
        label: "User Manual",
        route: "usermanual",
        "data-testid": "user-manual",
        icon: <UserOutlined />,
      },
      {
        key: 10.2,
        label: "About",
        route: "about",
        "data-testid": "about",
        icon: <WarningOutlined />,
      },
    ],
  },
];
