import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllUsers = createAsyncThunk("user/getAll", async (req, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.USERS_ALL}?role=${req}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getUsersStats = createAsyncThunk("user/getUsersStats", async (req, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.USERS_ALL}/statistics`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const createUser = createAsyncThunk("user", async (params, { rejectWithValue }) => {
  const { fieldsValue } = params;
  const options = {
    url: API_URL.CREATE_USER,
    method: "POST",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const deleteUser = createAsyncThunk("user/delete", async (params, { rejectWithValue }) => {
  const { id } = params;
  const options = {
    url: `${API_URL.USERS_ALL}/${id}`,
    method: "DELETE",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const updateUser = createAsyncThunk("user/udpate", async (params, { rejectWithValue }) => {
  const { fieldsValue, id } = params;
  const options = {
    url: `${API_URL.CREATE_USER}/${id}`,
    method: "PUT",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getUser = createAsyncThunk("user/getUser", async (params, { rejectWithValue }) => {
  const { id } = params;
  const options = {
    url: `${API_URL.USERS_ALL}/${id}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { getAllUsers, getUsersStats, createUser, deleteUser, updateUser, getUser };
