import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllRecommendationExecutor = createAsyncThunk(
  "recommendationExecutor/all",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION_EXECUTOR}?page=${page}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
const getRecommendationExecutorFilter = createAsyncThunk(
  "recommendationExecutor/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION_EXECUTOR}?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const scanRecommendationExecutor = createAsyncThunk(
  "recommendationExecutor/scan",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.RECOMMENDATION_EXECUTOR}/pull?page=${params.page}&limit=${params.limit}`,
      method: "POST",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteAllRecommendationExecutor = createAsyncThunk(
  "recommendationExecutor/deleteAll",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.RECOMMENDATION_EXECUTOR}/deleteAll`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const pushScanRecommendationExecutor = createAsyncThunk(
  "recommendationExecutor/pushScan",
  async (params, { rejectWithValue }) => {
    // const { limit = 50, page = 1 } = params;
    const options = {
      url: `${API_URL.RECOMMENDATION_EXECUTOR}/push`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllRecommendationExecutor,
  getRecommendationExecutorFilter,
  scanRecommendationExecutor,
  deleteAllRecommendationExecutor,
  pushScanRecommendationExecutor,
};
