import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  Form,
  Button,
  Space,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreditCardOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getAllResourcesWithoutLabelsReports,
  getFilterResourcesWithoutLabelsReports,
} from "redux/action";
import { afterDecimalTwoDigit } from "utils/helper";

const filterFields = [
  {
    id: 1,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "resourceName",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
];

const Resources_Without_Labels = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const { isLoading, resourcesWithoutLabelsReports } = useSelector((state) => state.reports);
  const { resourcesWithoutLabelsReport, totalPages, currentPage, count } =
    resourcesWithoutLabelsReports;

  const columns = [
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resource",
      render: (_, record) => <span>{`${record.resourceName.split("/").at(-1)}`}</span>,
    },
    {
      title: "Total Billing(USD)",
      dataIndex: "billing",
      key: "billing",
      render: (_, record) => <span>{`${afterDecimalTwoDigit(record.billing)}`}</span>,
    },
    // {
    //   title: "Billing (USD) After Recommendations",
    //   dataIndex: "billingAfterRecommendation",
    //   key: "billingAfterRecommendation",
    //   width: 100,
    //   render: (_, record) => (
    //     <span>{`${afterDecimalTwoDigit(record.billingAfterRecommendation)}`}</span>
    //   ),
    // },
    // {
    //   title: "Potential Savings (USD)",
    //   dataIndex: "savings",
    //   key: "savings",
    //   width: 100,
    //   render: (_, record) => <span>{`${afterDecimalTwoDigit(record.savings)}`}</span>,
    // },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getAllResourcesWithoutLabelsReport();
  }, []);

  const onFormFinish = ({ resourceName, projectId }) => {
    if (!resourceName && !projectId) return;
    setSearchData({});
    const data = {
      resourceName,
      projectId,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getFilterResourcesWithoutLabelsReports(data));
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getFilterResourcesWithoutLabelsReports({ page: pageNum, ...searchData }))
      : dispatch(getAllResourcesWithoutLabelsReports({ page: pageNum }));
  };

  const getAllResourcesWithoutLabelsReport = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllResourcesWithoutLabelsReports({ page: 1 }));
  };

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CreditCardOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2">Unassigned Resources</span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={12} xs={12} sm={12} lg={12} />
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllResourcesWithoutLabelsReport}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={resourcesWithoutLabelsReport}
          isLoading={isLoading}
          rowKey="_id"
          pagination={{ total: count, onChange: (pageNum) => onPageChange(pageNum) }}
        />
      </Layout>
    </Layout>
  );
};

export default Resources_Without_Labels;
