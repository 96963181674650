import { Navigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useLocalStorage } from "hooks";
import { ROUTES } from "constants";
import { useSelector } from "react-redux";
import AccessDenied from "pages/AccessDenied";
const PrivateRoute = ({ children, roles }) => {
  const { isUserProfileLoading, user } = useSelector((state) => state.auth);
  const { getItem } = useLocalStorage();
  const accessToken = getItem("access_token");
  if (isUserProfileLoading) {
    return <p className="container">Checking auth..</p>;
  }
  const userHasRequiredRole = user && roles.includes(user.role) ? true : false;

  if (!userHasRequiredRole && accessToken) return <AccessDenied />;

  return accessToken ? children : <Navigate to={ROUTES.ADMIN} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
