import {
  Layout,
  Form,
  Button,
  DynamicField as LoginField,
  LoginContainer,
  ForgotPassword,
  Row,
  StickyFooter,
  Col,
} from "components";
import { UI_TEXT, ROUTES, UI_VALIDATION } from "constants";
import { useLocalStorage } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authLogin, authForgotPassword, authUserProfile } from "redux/action";
import packageJson from "../../package.json";
import banner from "assets/images/cloudcostimg.png";
import { Logo } from "components/atoms";

const loginData = [
  {
    id: 1,
    name: "email",
    label: "Email",
    placeholder: "abc@ccoe.com",
    type: "Text",
    rules: [{ required: true, type: "email" }],
  },
  {
    id: 2,
    name: "password",
    label: "Password",
    placeholder: "*******",
    subType: "Password",
    type: "Text",
    rules: [{ required: true }],
  },
];

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    isLoadingForgot,
    userToken: { token: accessToken },
  } = useSelector((state) => state.auth);
  const { setItem } = useLocalStorage();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (accessToken) {
      setItem("access_token", accessToken);
      dispatch(authUserProfile());
      navigate(ROUTES.DASHBOARD);
    }
  }, [accessToken]);

  const onLogin = (loginCredentials) => {
    dispatch(authLogin(loginCredentials));
  };

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleModalOk = async (forgotCredentials) => {
    await dispatch(authForgotPassword(forgotCredentials));
    setIsModalOpen(!isModalOpen);
  };

  const handleModalCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <Row>
        <Col
          span={14}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo
            image={banner}
            altText="CCOE banner"
            additionalProps={{ width: "100%", height: "100%" }}
          />
        </Col>
        <Col span={10}>
          <Layout className="h-90 h-screen items-center justify-center">
            <LoginContainer>
              <Form
                hideRequiredMark
                name="loginForm"
                initialValues={{
                  remember: false,
                }}
                layout="vertical"
                autoComplete="off"
                validateMessages={UI_VALIDATION}
                onFinish={onLogin}
                onFinishFailed={() => {
                  // TODO: can check failure if needed
                }}
              >
                <LoginField inputsData={loginData} span={24} xs={24} sm={24} lg={24} />
                <Button
                  isLoading={isLoading}
                  text={UI_TEXT?.CTA.LOGIN}
                  additionalProps={{ htmlType: "submit", className: "w-full" }}
                />
              </Form>

              <ForgotPassword
                title="Forgot Password"
                isModalOpen={isModalOpen}
                handleOpen={handleModalOpen}
                handleOk={handleModalOk}
                handleCancel={handleModalCancel}
                confirmLoading={isLoadingForgot}
              />
              <Button type="link" primary onClick={handleModalOpen} text="Forgot password?" />
            </LoginContainer>
          </Layout>
        </Col>
      </Row>

      <StickyFooter>
        {UI_TEXT.COMMON.ALL_RIGHT_RESERVED}
        {`v${packageJson.version}`}
      </StickyFooter>
    </>
  );
};

export default Login;
