import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllRecommendationGenerator = createAsyncThunk(
  "recommendationGenerator/all",
  async (params, { rejectWithValue }) => {
    const { page = 1, query } = params;
    const options = {
      url: `${API_URL.RECOMMENDDATION_GENERATORS}?page=${page}&query=${query}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
const getRecommendationGeneratorFilter = createAsyncThunk(
  "recommendationGenerator/filter",
  async (params, { rejectWithValue }) => {
    const { query, page = 1 } = params;
    const options = {
      url: `${API_URL.RECOMMENDDATION_GENERATORS}?page=${page}`, //&query=${query}
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
const updateRecommendationAndHistory = createAsyncThunk(
  "recommendationGenerator/updateRecommendationAndHistory",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.RECOMMENDDATION_GENERATORS}?page=${params?.page}&query=${params?.queryParams}`,
      method: "PUT",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const scanRecommendationGenerator = createAsyncThunk(
  "recommendationGenerator/scan",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.RECOMMENDDATION_GENERATORS}/pull?page=${params.page}&limit=${params.limit}`,
      method: "POST",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteAllRecommendationGenerator = createAsyncThunk(
  "recommendationGenerator/deleteAll",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.RECOMMENDDATION_GENERATORS}/deleteAll`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllRecommendationGenerator,
  getRecommendationGeneratorFilter,
  scanRecommendationGenerator,
  deleteAllRecommendationGenerator,
  updateRecommendationAndHistory,
};
