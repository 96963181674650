import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  RangePicker,
  Form,
  Button,
  Space,
  Tag,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMetricsAggregators,
  getAllMetricTypes,
  getMetricsAggregatorsFilter,
} from "redux/action";
import { SearchOutlined, CopyOutlined, RedoOutlined } from "@ant-design/icons";
import { afterDecimalFourDigit } from "utils/helper";
import moment from "moment";
import { NavLink } from "react-router-dom";

const filterFields = [
  {
    id: 1,
    name: "name",
    label: "Resource",
    placeholder: "Resource",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
];

const MetricsAggregator = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [metricsAnalysis, setMetricsAnalysis] = useState([]);
  const [isLoadingTransform, setIsLoadingTransform] = useState(true);

  const { isLoading, metricAggregatorData } = useSelector((state) => state.metricAggregator);
  const { metricAggregatorsData, count } = metricAggregatorData;
  const { metricTypesData } = useSelector((state) => state.metricTypes);

  const filterMetricTypes = (metric) => {
    if (metric) {
      const { ccoeeMetricType, mean, median, mode, variance, standardDeviation, maximum, minimum } =
        metric;
      // const { name, unit } = metricTypesData[ccoeeMetricType];
      return (
        <>
          <Tag className="mv-2">
            <b>{ccoeeMetricType}</b>
          </Tag>
          <br />
          <Tag className="mv-2">
            <b>Mean</b>:{afterDecimalFourDigit(mean)}
          </Tag>
          <Tag className="mv-2">
            <b>Median</b>:{afterDecimalFourDigit(median)}
          </Tag>
          <Tag className="mv-2">
            <b>Mode</b>:{afterDecimalFourDigit(mode)}
          </Tag>
          <Tag className="mv-2">
            <b>Variance</b>:{afterDecimalFourDigit(variance)}
          </Tag>
          <Tag className="mv-2">
            <b>SD</b>:{afterDecimalFourDigit(standardDeviation)}
          </Tag>
          <Tag className="mv-2">
            <b>Maximum</b>:{afterDecimalFourDigit(maximum)}
          </Tag>
          <Tag className="mv-2">
            <b>Minimum</b>:{afterDecimalFourDigit(minimum)}
          </Tag>
        </>
      );
    }
  };

  const limit = 50;
  const columns = [
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "fromDate",
      width: 150,
    },
    {
      title: "To Date",
      dataIndex: "toDate",
      key: "toDate",
      width: 150,
    },
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
      width: 150,
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resourceName",
      width: 150,
    },
    {
      title: "Metric 1",
      dataIndex: "0",
      key: "0",
      width: 230,
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 2",
      dataIndex: "1",
      key: "1",
      width: 230,
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 3",
      dataIndex: "2",
      key: "2",
      width: 230,
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 4",
      dataIndex: "3",
      key: "3",
      width: 230,
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "Metric 5",
      dataIndex: "4",
      key: "4",
      width: 250,
      render: (metric) => filterMetricTypes(metric),
    },
    {
      title: "More Details",
      dataIndex: "",
      key: "",
      fixed: "right",
      width: 100,
      render: (metric) => (
        <NavLink to={`/dashboard/metrics/moreinformation/?metric=${metric._id}`}>
          <div>{"view more"}</div>
        </NavLink>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    dispatch(getAllMetricTypes());
    getAllMetricsAggregatorsDetails();
  }, []);

  useEffect(() => {
    if (metricAggregatorsData) {
      setIsLoadingTransform(true);

      const transformData = metricAggregatorsData.map((item) => {
        const { metricAggregatorDetailList } = item;
        const result = metricAggregatorDetailList.map((item, ind) => item);
        return { ...item, ...result };
      });

      setMetricsAnalysis(transformData);
      setIsLoadingTransform(false);
    }
  }, [metricAggregatorsData]);

  const onFormFinish = ({ name, month, projectId }) => {
    if (!name && !projectId && !month) return;

    setSearchData({});
    const data = {
      month: month ? moment(month).format("YYYY-MM").toUpperCase() : null,
      name: name,
      projectId: projectId,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }

    dispatch(getMetricsAggregatorsFilter(data));
    setSearchData(data);
    setIsFilter(true);
  };

  const getAllMetricsAggregatorsDetails = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(
      getAllMetricsAggregators({
        page: 1,
        limit,
      })
    );
  };

  const onPageChange = (pageNum) =>
    isFilter
      ? dispatch(getMetricsAggregatorsFilter({ page: pageNum, ...searchData }))
      : dispatch(
          getAllMetricsAggregators({
            page: pageNum,
            limit,
          })
        );

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CopyOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Metrics Analysis</span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={8} xs={8} sm={8} lg={8} />
          <Col span={8} xs={8} sm={8} lg={8} className="flex ">
            {/* <RangePicker
              name="month"
              onChange={(date, dateString) => {
                return date, dateString;
              }}
              label="Select Month"
            /> */}
          </Col>

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllMetricsAggregatorsDetails}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={metricsAnalysis}
          pagination={{ total: count, onChange: (e) => onPageChange(e) }}
          isLoading={isLoading || isLoadingTransform}
          rowKey="_id"
        />
      </Layout>
    </Layout>
  );
};

export default MetricsAggregator;
