import {
  DynamicField as FilterField,
  Table,
  Layout,
  Col,
  Row,
  Form,
  DatePicker,
  Button,
  Space,
  Tooltip,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreditCardOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  getAllRecommendationsAggregatesReports,
  getFilterRecommendationsAggregatesReports,
  downloadCSVRecommendationsAggregatesReports,
} from "redux/action";
import moment from "moment";

const Recommendations_Aggregates = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const { isLoading, recommendationsAggregatesReports } = useSelector((state) => state.reports);

  const { recommendationsAggregatesReport, totalPages, currentPage, count } =
    recommendationsAggregatesReports;

  const filterFields = [
    {
      id: 1,
      name: "projectId",
      label: "project Id",
      placeholder: "project Id",
      type: "Text",
      rules: [{ required: false }],
    },
  ];

  const toolTipMessages = {
    TotalRecommendations: "The total number of recommendations raised in the month.",

    TotalActive: "The total number of recommendations still active, and not launched yet.",

    TotalDeclined: "The total number of recommendations rejected/declined by the team.",

    TotalInProgressed:
      "The total number of recommendations that are launched and are in progress right now, not completed yet.",

    TotalCompleted:
      "The total number of recommendations that are being launched and completed successfully.",

    TotalWithdrawn:
      "The total number of recommendations that were withdrawn by the system automatically based on the new utilization pattern of the resource.",
  };

  const columns = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      render: (_, { team }) => <span>{team == "Orphan" ? "UNKNOWN" : team}</span>,
    },
    {
      title: "project Id",
      dataIndex: "projectId",
      key: "projectId",
    },
    {
      title: "Month",
      dataIndex: "monthAndYear",
      key: "monthAndYear",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total Recommendations</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalRecommendations}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "total",
      key: "total",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total Active</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalActive}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalActive",
      key: "totalActive",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total Declined</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalDeclined}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalDeclined",
      key: "totalDeclined",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total In-progressed</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalInProgressed}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalLaunched",
      key: "totalLaunched",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total Completed</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalCompleted}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalExecuted",
      key: "totalExecuted",
      width: 150,
    },
    {
      title: (
        <div>
          <span>Total Withdrawn</span>
          <Tooltip title={<span>{`${toolTipMessages.TotalWithdrawn}`}</span>}>
            <InfoCircleOutlined className="ml-1" />
          </Tooltip>
        </div>
      ),
      dataIndex: "totalWithdrawn",
      key: "totalWithdrawn",
      width: 150,
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    getAllAggregateReports();
  }, []);

  const onFormFinish = ({ team, month, projectId }) => {
    if (!month && !projectId) return;
    setSearchData({});
    let data = {
      monthAndYear: month ? moment(month).format("M-YYYY") : null,
      team: team,
      projectId: projectId,
    };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getFilterRecommendationsAggregatesReports(data));
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getFilterRecommendationsAggregatesReports({ page: pageNum, ...searchData }))
      : dispatch(getAllRecommendationsAggregatesReports({ page: pageNum }));
  };

  const getAllAggregateReports = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllRecommendationsAggregatesReports({ page: 1 }));
  };

  const downloadCSV = () => {
    dispatch(downloadCSVRecommendationsAggregatesReports(searchData));
  };
  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <CreditCardOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2">Recommendation Summary </span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between-fahad">
          <FilterField inputsData={filterFields} span={12} xs={24} sm={12} lg={12} />
          <Col span={12} xs={12} sm={12} lg={12} className="flex ">
            <DatePicker
              name="month"
              label="Select Month"
              type="month"
              // onChange={(e) => moment(e._d).format("MMMM")}
            />
          </Col>
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              {/* <Button
                type="primary"
                onClick={downloadCSV}
                text={"Download CSV"}
                icon={<DownloadOutlined />}
              /> */}
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllAggregateReports}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={recommendationsAggregatesReport}
          isLoading={isLoading}
          rowKey="team"
          scroll={{ x: true }}
          pagination={{ total: count, onChange: (pageNum) => onPageChange(pageNum) }}
        />
      </Layout>
    </Layout>
  );
};

export default Recommendations_Aggregates;
