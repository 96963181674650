const useLocalStorage = () => {
  const getItem = (key) => {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) {
      return null;
    }
    return JSON.parse(storedValue);
  };

  const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key) => {
    localStorage.removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};
export default useLocalStorage;
