import PropTypes from "prop-types";
import { Menu as AntMenu } from "antd";
import React from "react";

const Menu = ({ menuItems, handleOnMenuClick }) => {
  return (
    <AntMenu
      className="large_text"
      theme="light"
      data-testid="atom-menu"
      mode="inline"
      inlineIndent={44}
      width={400}
      onScroll={true}
      items={menuItems}
      onClick={handleOnMenuClick}
    />
  );
};

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Menu;
