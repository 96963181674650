import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  Form,
  Button,
  Space,
  Tag,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCloudAccounts, deleteCloudAccount, getCloudAccountFilters } from "redux/action";
import { Link } from "react-router-dom";
import { removeCloudAccount } from "redux/reducers/CloudAccountReducer";
import { usePermission } from "hooks";
import roles from "config/roles";
import { CloudOutlined, SearchOutlined, RedoOutlined } from "@ant-design/icons";

const states = [
  { value: true, title: "ENABLED" },
  { value: false, title: "DISABLED" },
];

const ViewAllCloudAccount = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const { isLoading, isDeleteCloudAccount, cloudAccountData } = useSelector(
    (state) => state.cloudAccount
  );
  const [cloudAccountId, setCloudAccount] = useState(null);

  const { cloudAccountsData, totalPages, currentPage, count } = cloudAccountData;

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      render: (_, record) => (
        <Tag color={record.enabled ? "green" : "pink"}>
          {record.enabled ? "ENABLED" : "DISABLED"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      hidden: usePermission([roles.SUPER_ADMIN, roles.ADMIN]),
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/cloud/updateProfile/${record._id}`} data-testid="edit">
            Edit
          </Link>
          <a
            data-testid="delete"
            onClick={(e) => {
              deleteRecord(record._id, e);
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const filterFields = [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      placeholder: "Description",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 4,
      name: "provider",
      label: "Provider",
      placeholder: "Provider",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 5,
      name: "enabled",
      label: "Status",
      placeholder: "Status",
      type: "Select",
      rules: [{ required: false }],
      optType: "Option",
      option: states,
    },
  ];

  useEffect(() => {
    dispatch(getAllCloudAccounts());
  }, []);

  useEffect(() => {
    if (isDeleteCloudAccount) {
      dispatch(removeCloudAccount(cloudAccountId));
    }
  }, [isDeleteCloudAccount]);

  const deleteRecord = (id, e) => {
    e.preventDefault();
    setCloudAccount(id);
    dispatch(deleteCloudAccount({ id }));
  };

  const onFormFinish = ({ name, description, projectId, provider, enabled }) => {
    if (!name && !description && !projectId && !provider && !enabled) return;
    setSearchData({});
    const data = {
      name: name,
      description: description,
      projectId: projectId,
      provider: provider,
      enabled: enabled,
    };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getCloudAccountFilters(data)); //TODO::  filter
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getCloudAccountFilters({ page: pageNum, ...searchData })) //TODO::  filter
      : dispatch(getAllCloudAccounts({ page: pageNum }));
  };

  const getAllCloudAccount = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllCloudAccounts({ page: 1 })); 
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <CloudOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">View All Cloud Account</span>
      </Col>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={6} xs={12} sm={6} lg={6} />
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllCloudAccount}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={cloudAccountsData}
          isLoading={isLoading}
          rowKey="_id"
          pagination={{
            // total: count,
            onChange: (pageNum) => onPageChange(pageNum),
          }}
        />
      </Layout>
    </Layout>
  );
};

export default ViewAllCloudAccount;
