import { useSelector } from "react-redux";

const usePermission = (allowedRoles) => {
  const { user } = useSelector((state) => state.auth);

  const hasPermission = allowedRoles.includes(user.role);
  return !hasPermission;
};

export default usePermission;
