import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllResources = createAsyncThunk("resources/all", async (_, { rejectWithValue }) => {
  const options = {
    url: API_URL.ALL_RESOURCES,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getResourceFilter = createAsyncThunk(
  "resources/filter",
  async (params, { rejectWithValue }) => {
    const options = {
      url: API_URL.ALL_RESOURCES,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getResourceCount = createAsyncThunk(
  "resources/getResourceCount",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.ALL_RESOURCES}/count`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const createResource = createAsyncThunk("resource/create", async (params, { rejectWithValue }) => {
  const { fieldsValue } = params;
  const options = {
    url: API_URL.RESOURCE,
    method: "POST",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const scanResources = createAsyncThunk("resource/scan", async (params, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.ALL_RESOURCES}/pull`,
    method: "POST",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const deleteAllResources = createAsyncThunk(
  "resource/deleteAll",
  async (_, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.ALL_RESOURCES}/deleteAll`,
      method: "DELETE",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const deleteResource = createAsyncThunk("resource/delete", async (params, { rejectWithValue }) => {
  const { id } = params;
  const options = {
    url: `${API_URL.RESOURCE}/${id}`,
    method: "DELETE",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const updateResource = createAsyncThunk("resource/udpate", async (params, { rejectWithValue }) => {
  const { fieldsValue, id } = params;
  const options = {
    url: `${API_URL.RESOURCE}/${id}`,
    method: "PUT",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getResource = createAsyncThunk(
  "resource/getResource",
  async (params, { rejectWithValue }) => {
    const { id } = params;
    const options = {
      url: `${API_URL.RESOURCE}/${id}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  getAllResources,
  getResourceFilter,
  getResourceCount,
  createResource,
  scanResources,
  deleteResource,
  updateResource,
  getResource,
  deleteAllResources,
};
