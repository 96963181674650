import { Header } from "components/atoms";
import { useEffect } from "react";
import PropTypes from "prop-types";

const HeaderTop = ({ isCollapsed, setCollapsed }) => {
  const toggleCollapsed = () => {
    setCollapsed(!isCollapsed);
    localStorage.setItem("isCollapse", isCollapsed);
  };
  const checkCollapsed = localStorage.getItem("isCollapse");

  useEffect(() => {
    if (checkCollapsed === "true") {
      setCollapsed(false);
    } else if (checkCollapsed === "false") {
      setCollapsed(true);
    }
  }, []);

  return <Header data-testid="htop" toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed} />;
};
HeaderTop.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default HeaderTop;
