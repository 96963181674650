import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllMetrics = createAsyncThunk("metrics/all", async (params, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.METRICS}?page=${params.page}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getAllMetricsFilter = createAsyncThunk(
  "metrics/filter",
  async (params, { rejectWithValue }) => {
    const { page = 1 } = params;
    const options = {
      url: `${API_URL.METRICS}?page=${page}`,
      method: "POST",
      isAuth: true,
      data: {
        ...params,
      },
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const getMetricsByResource = createAsyncThunk(
  "metrics/byRecource",
  async (params, { rejectWithValue }) => {
    const options = {
      url: `${API_URL.METRICS}/${params.id}?page=${params.page}&limit=${params.limit}`,
      method: "GET",
      isAuth: true,
    };
    try {
      const data = await useFetcher(options);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const scanMetrics = createAsyncThunk("metrics/scan", async (params, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.METRICS}/pull?page=${params.page}&limit=${params.limit}`,
    method: "POST",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const deleteAllMetrics = createAsyncThunk("metrics/deleteAll", async (_, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.METRICS}/deleteAll`,
    method: "DELETE",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { getAllMetrics, scanMetrics, deleteAllMetrics, getMetricsByResource,getAllMetricsFilter };
