import { TeamOutlined } from "@ant-design/icons";
import { DynamicField as UserField, Form, Row, Col, Button } from "components";
import roles from "config/roles";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authChangePassword, getAllTeams } from "redux/action";
import { updateUser, getUser } from "redux/action/UserAction";
import { customPhoneNumber, customValidPassword, numberValidation } from "utils";

const resetPasswordField = [
  {
    id: 1,
    name: "password",
    label: "Password",
    placeholder: "*******",
    subType: "Password",
    type: "Text",
    rules: [{ required: true }, customValidPassword],
  },
  {
    id: 2,
    name: "confirmPassword",
    label: "Confirm Password",
    placeholder: "*******",
    subType: "Password",
    type: "Text",
    rules: [
      { required: true },
      customValidPassword,
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("The two passwords that you entered do not match!");
        },
      }),
    ],
  },
];

const UpdateUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoadingUser, userProfile } = useSelector((state) => state.user);
  const { user, isLoadingReset } = useSelector((state) => state.auth);
  const { isLoadingTeam, teamData } = useSelector((state) => state.team);
  const [selectedRole, setSelectedRole] = useState(0);

  const updateFormFields = (role) => {
    const findField = updateUserFields.findIndex((field) => {
      return field.id === 8;
    });
    if (role === roles.TEAM_MEMBER) {
      if (findField === -1)
        updateUserFields.splice(7, 0, {
          id: 8,
          name: "teamId",
          label: "Team",
          placeholder: "Team",
          type: "Select",
          rules: [{ required: true }],
          option: (teamData || []).map((d) => ({
            value: d._id,
            title: d.name,
          })),
        });
    } else {
      if (findField !== -1) updateUserFields.splice(findField, 1);
    }
    setSelectedRole(role);
  };

  const handleChange = (value) => {
    updateFormFields(Number(value));
  };

  let [updateUserFields, setUpdateUserFields] = useState([
    // {
    //   id: 1,
    //   name: "employee_id",
    //   label: "Employee ID",
    //   placeholder: "Employee ID",
    //   type: "Text",
    //   //rules: [{ required: true }, numberValidation],
    //   disabled: true,
    // },
    {
      id: 2,
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      type: "Text",
      rules: [{ required: true }],
    },
    {
      id: 3,
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      type: "Text",
      rules: [{ required: true }],
    },
    {
      id: 4,
      name: "alias",
      label: "Alias Name",
      placeholder: "Alias Name",
      type: "Text",
      rules: [{ required: true }],
    },
    {
      id: 5,
      name: "gender",
      label: "Gender",
      placeholder: "Gender",
      type: "Select",
      rules: [{ required: true }],
      optType: "Option",
      option: [
        {
          value: "MALE",
          title: "Male",
        },
        {
          value: "FEMALE",
          title: "Female",
        },
      ],
    },
    {
      id: 6,
      name: "email",
      label: "Email",
      placeholder: "xyz@gmail.com",
      type: "Text",
      rules: [{ required: true, type: "email", disabled: true }],
      disabled: true,
    },
    {
      id: 7,
      name: "role",
      label: "Role",
      placeholder: "Role",
      type: "Select",
      rules: [{ required: true }],
      optType: "Option",
      additionalProps: {
        onChange: handleChange,
      },
      option: [
        {
          value: "1",
          title: "Super Admin",
          isDisabled: true,
        },
        {
          value: "2",
          title: "Admin",
        },
        {
          value: "3",
          title: "Team Member",
        },
        {
          value: "4",
          title: "Manager",
        },
      ],
    },
    {
      id: 9,
      name: "cellNumber",
      label: "Cell Number",
      placeholder: "Cell Number",
      type: "Text",
      subType: "Text",
      rules: [{ required: false }, customPhoneNumber],
    },
    {
      id: 10,
      name: "enabled",
      label: "Status",
      type: "Switch",
      additionalProps: {
        checkedChildren: "Enabled",
        unCheckedChildren: "Disabled",
      },
      defaultChecked: true,
      rules: [{ required: false }],
    },
  ]);

  const isRoleDisabled = (userEmpId, id, role) => {
    // Disabled role, team and hide status when user on their profile
    if (userEmpId === id) {
      updateUserFields[6].disabled = true;
      if (Number(role) === roles.TEAM_MEMBER) {
        const findTeam = updateUserFields.findIndex((field) => {
          return field.id === 8;
        });
        if (findTeam !== -1) {
          updateUserFields[7].disabled = true;
        }
      }
      updateUserFields = updateUserFields.slice(0, updateUserFields.length - 1);
    } else updateUserFields[6].disabled = false;
  };

  const [form] = Form.useForm();
  const onFormFinish = (fieldsValue) => {
    delete fieldsValue["employee_id"];
    delete fieldsValue["email"];

    if (Number(fieldsValue.role) !== roles.TEAM_MEMBER) {
      delete fieldsValue.teamId;
    }
    dispatch(updateUser({ fieldsValue, id }));
  };

  useEffect(() => {
    setUpdateUserFields(updateUserFields);
  }, [selectedRole]);

  useEffect(() => {
    dispatch(getAllTeams());
  }, []);

  useEffect(() => {
    dispatch(getUser({ id }));
  }, [isLoadingTeam]);

  useEffect(() => {
    const { _id, firstName, lastName, alias, gender, email, role, cellNumber, enabled, teamId } =
      userProfile;
    form.setFieldsValue({
      _id,
      firstName,
      lastName,
      gender,
      email,
      role: role?.toString(),
      alias,
      cellNumber,
      teamId,
      enabled,
    });
    updateFormFields(role);
    isRoleDisabled(user._id, id, user.role);
  }, [isLoadingUser]);

  const onResetPassword = (fieldsValue) => {
    dispatch(
      authChangePassword({
        password: fieldsValue.password,
        userId: id,
      })
    );
  };

  return (
    <div>
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <TeamOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">Update User</span>
      </Col>
      <div className="mt-4">
        <Form
          hideRequiredMark
          form={form}
          name="createMembershipForm"
          layout="vertical"
          onFinish={onFormFinish}
          validateMessages={UI_VALIDATION}
        >
          <Row className="fields-row" gutter={20} type="flex" justify="space-between">
            <UserField inputsData={updateUserFields} span={12} xs={24} sm={12} lg={12} />

            {/* TODO: add this into dynamic actions, if needed */}
            <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={UI_TEXT.CTA.UPDATE}
              />
            </Col>
          </Row>
        </Form>
        {user._id === id && (
          <>
            <h1>Change Password</h1>
            <Form
              hideRequiredMark
              name="changePasswordForm"
              layout="vertical"
              autoComplete="off"
              validateMessages={UI_VALIDATION}
              onFinish={onResetPassword}
              onFinishFailed={() => {
                // TODO: can check failure if needed
              }}
            >
              <Row className="fields-row" gutter={20} type="flex" justify="space-between">
                <UserField inputsData={resetPasswordField} span={24} xs={24} sm={24} lg={24} />
              </Row>
              <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
                <Button
                  isLoading={isLoadingReset}
                  type="primary"
                  text={UI_TEXT.CTA.CHANGE_PASSWORD}
                  additionalProps={{ htmlType: "submit" }}
                />
              </Col>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};
export default UpdateUser;
