import { UserAddOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { DynamicField as TeamField, Form, Row, Col, Button } from "components";
import { UI_VALIDATION, UI_TEXT } from "constants";
import { useDispatch } from "react-redux";
import { createTeam } from "redux/action/TeamAction";

// TODO: needs to adjust in to the fixtures files
const creatTeamFields = [
  {
    id: 1,
    name: "name",
    label: "Name",
    placeholder: "Name",
    type: "Text",
    subType: "Text",
    rules: [{ required: true }],
  },
  {
    id: 2,
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Text",
    subType: "TextArea",
    additionalProps: {
      maxLength: 100,
      rows: 4,
    },
    rows: 4,
    rules: [{ required: false }],
  },
  {
    id: 3,
    name: "enabled",
    label: "Status",
    type: "Switch",
    additionalProps: {
      checkedChildren: "Enabled",
      unCheckedChildren: "Disabled",
    },
    defaultChecked: true,
    rules: [{ required: false }],
  },
];

const CreateTeam = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFormFinish = (fieldsValue) => {
    dispatch(createTeam({ fieldsValue }));
    form.resetFields();
  };

  return (
    <div>
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <UserAddOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">Add Team</span>
      </Col>
      <div className="mt-4">
        <Form
          hideRequiredMark
          initialValues={{ enabled: true }}
          form={form}
          name="createMembershipForm"
          layout="vertical"
          onFinish={onFormFinish}
          validateMessages={UI_VALIDATION}
        >
          <Row className="fields-row" gutter={20} type="flex" justify="space-between">
            <TeamField inputsData={creatTeamFields} span={12} xs={24} sm={12} lg={12} />
            {/* TODO: add this into dynamic actions, if needed */}
            <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={UI_TEXT.TEAM.CREATE_TEAM}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default CreateTeam;
