import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  Form,
  Button,
  Space,
  Tag,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteTeam, getAllTeams, getTeamFilters } from "redux/action";
import { removeTeam } from "redux/reducers/TeamReducer";
import roles from "config/roles";
import { usePermission } from "hooks";
import { TeamOutlined, SearchOutlined, RedoOutlined } from "@ant-design/icons";
// TODO: needs to adjust in to the fixtures files

const ViewAllTeam = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const { isLoading, isDeleteTeam, teamData } = useSelector((state) => state.team);
  const { teamsData, count, currentPage, totalPages } = teamData;
  const [teamId, setTeamId] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Status",
    //   dataIndex: "enabled",
    //   key: "enabled",
    //   render: (_, record) => (
    //     <Tag color={record.enabled ? "green" : "pink"}>
    //       {record.enabled ? "ENABLED" : "DISABLED"}
    //     </Tag>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      hidden: usePermission([roles.SUPER_ADMIN, roles.ADMIN]),
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/team/updateTeam/${record._id}`} data-testid="edit">
            Edit
          </Link>
          <a
            data-testid="delete"
            onClick={(e) => {
              deleteRecord(record._id, e);
            }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const filterFields = [
    {
      id: 1,
      name: "name",
      label: "Name",
      placeholder: "Name",
      type: "Text",
      rules: [{ required: false }],
    },
    {
      id: 2,
      name: "description",
      label: "Description",
      placeholder: "Description",
      type: "Text",
      rules: [{ required: false }],
    },
  ];

  useEffect(() => {
    getAllTeam();
  }, []);

  useEffect(() => {
    if (isDeleteTeam) {
      dispatch(removeTeam(teamId));
    }
  }, [isDeleteTeam]);

  const deleteRecord = (id, e) => {
    e.preventDefault();
    setTeamId(id);
    dispatch(deleteTeam({ id }));
  };

  const onFormFinish = ({ name, description }) => {
    if (!name && !description) return;
    setSearchData({});
    const data = {
      name: name,
      description: description,
    };
    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }
    dispatch(getTeamFilters(data)); //TODO::  filter
    setSearchData(data);
    setIsFilter(true);
  };

  const onPageChange = (pageNum) => {
    isFilter
      ? dispatch(getTeamFilters({ page: pageNum, ...searchData })) //TODO::  filter
      : dispatch(getAllTeams({ page: pageNum }));
  };

  const getAllTeam = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(getAllTeams()); //{ page: 1 }
  };

  return (
    <Layout className="bg-transparent">
      <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
        <TeamOutlined style={{ fontSize: "24px" }} />
        <span className="text-2xl ml-2">View All Team</span>
      </Col>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={12} xs={12} sm={12} lg={12} />
          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllTeam}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={teamsData}
          isLoading={isLoading}
          rowKey="_id"
          pagination={{
            total: count,
            onChange: (pageNum) => onPageChange(pageNum),
          }}
        />
      </Layout>
    </Layout>
  );
};

export default ViewAllTeam;
