import {
  Layout,
  Form,
  Button,
  DynamicField as ResetPasswordField,
  LoginContainer as ResetPasswordContainer,
  Row,
  StickyFooter,
  Col,
} from "components";
import { UI_TEXT, UI_VALIDATION } from "constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { authResetPassword } from "redux/action";
import { customValidPassword, toast } from "utils";
import banner from "assets/images/cloudcostimg.png";
import { Logo } from "components/atoms";

const resetPasswordData = [
  {
    id: 1,
    name: "password",
    label: "Password",
    placeholder: "*******",
    subType: "Password",
    type: "Text",
    rules: [{ required: true }, customValidPassword],
  },
  {
    id: 2,
    name: "confirmPassword",
    label: "Confirm Password",
    placeholder: "*******",
    subType: "Password",
    type: "Text",
    rules: [
      { required: true },
      customValidPassword,
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("The two passwords that you entered do not match!");
        },
      }),
    ],
  },
];

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isResetPassword, isLoadingReset } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isResetPassword) {
      navigate("/");
    }
  }, [isResetPassword]);

  const onResetPassword = (resetPasswordData) => {
    const getId = searchParams.get("id");
    const getToken = searchParams.get("token");
    if (getId && getToken) {
      dispatch(
        authResetPassword({
          token: getToken,
          password: resetPasswordData.password,
          userId: getId,
        })
      );
    } else {
      toast("error", "Token and id missing from url");
    }
  };

  return (
    <>
      <Row>
        <Col
          span={14}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo
            image={banner}
            altText="CCOE banner"
            additionalProps={{ width: "100%", height: "100%" }}
          />
        </Col>
        <Col span={10}>
          <Layout className="h-90 h-screen items-center justify-center">
            <ResetPasswordContainer>
              <Form
                hideRequiredMark
                name="loginForm"
                initialValues={{
                  remember: false,
                }}
                layout="vertical"
                autoComplete="off"
                validateMessages={UI_VALIDATION}
                onFinish={onResetPassword}
                onFinishFailed={() => {
                  // TODO: can check failure if needed
                }}
              >
                <ResetPasswordField
                  inputsData={resetPasswordData}
                  span={24}
                  xs={24}
                  sm={24}
                  lg={24}
                />
                <Button
                  isLoading={isLoadingReset}
                  text={UI_TEXT.CTA.RESET_PASSWORD}
                  additionalProps={{ htmlType: "submit", className: "w-full" }}
                />
              </Form>
            </ResetPasswordContainer>
          </Layout>
        </Col>
      </Row>
      <StickyFooter>{UI_TEXT.COMMON.ALL_RIGHT_RESERVED}</StickyFooter>
    </>
  );
};

export default ResetPassword;
