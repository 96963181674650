import {
  Table,
  Layout,
  Col,
  Row,
  DynamicField as FilterField,
  DatePicker,
  Form,
  Button,
  Space,
  Tag,
  Popover,
} from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RedoOutlined, RiseOutlined, BulbOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getAllMetricTypes,
  pushScanRecommendationExecutor,
  getAllRecommendationGenerator,
  getRecommendationGeneratorFilter,
  updateRecommendationAndHistory,
} from "redux/action";
import { afterDecimalTwoDigit } from "utils/helper";
import moment from "moment";

const filterFields = [
  {
    id: 1,
    name: "recommendation_Id",
    label: "Recommendation Id",
    placeholder: "Enter Full Recommendation Id",
    type: "Text",
    rules: [{ required: false }],
  },
  {
    id: 2,
    name: "projectId",
    label: "Project Id",
    placeholder: "Project Id",
    type: "Text",
    rules: [{ required: false }],
  },
];

const RecommendationGenerator = () => {
  const limit = 50;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchData, setSearchData] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };

  const onClickAction = (_id, action) => {
    const query = {
      queryParams: "active",
      recommendation_state: action,
      _id,
      page: currentPage,
    };
    hide();
    dispatch(updateRecommendationAndHistory(query));
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const { isLoading, recommendationGeneratorsData } = useSelector(
    (state) => state.recommendationGenerator
  );

  const { recommendationGeneratorData, totalPages, currentPage, count } =
    recommendationGeneratorsData;

  const columns = [
    {
      title: "Recommendation Id",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "recommendation_state",
      key: "recommendation_state",
      width: 100,
      render: (value, index) => (
        <Tag color={value == "Active" ? "success" : "processing"}>{value}</Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "recommendation_state",
      key: "recommendation_state",
      width: 200,
      render: (indexValue, { _id, ccoeMetricType }) => {
        return indexValue == "Active" ? (
          <Space size="middle">
            <Popover
              content={
                <>
                  <div>
                    <span>Action: {`${ccoeMetricType.ccoeAction}`} </span>
                    <br />
                    <span>CCOE Metric Type: {`${ccoeMetricType.displayName}`}</span>
                    <br />
                    <span>Resource Type: {`${ccoeMetricType?.monitoredResource}`}</span>
                  </div>
                  <Space size="middle">
                    <a onClick={() => onClickAction(_id, "Started")}>OK</a>
                    <a onClick={hide}>Close</a>
                  </Space>
                </>
              }
              title="Are you sure to Execute this recommendation?"
              trigger="click"
              open={open}
              onOpenChange={() => handleOpenChange()}
            >
              <Button type="primary" text={"Execute"} />
            </Popover>
            <Popover
              content={
                <>
                  <div>
                    <span>Action: {`${ccoeMetricType.ccoeAction}`} </span>
                    <br />
                    <span>CCOE Metric Type: {`${ccoeMetricType.displayName}`}</span>
                    <br />
                    <span>Resource Type: {`${ccoeMetricType?.monitoredResource}`}</span>
                  </div>
                  <Space size="middle">
                    <a onClick={() => onClickAction(_id, "Declined")}>OK</a>
                    <a onClick={hide}>Close</a>
                  </Space>
                </>
              }
              title="Are you sure to Declined this recommendation?"
              trigger="click"
              open={open}
              onOpenChange={() => handleOpenChange()}
            >
              <Button type="primary" text={"Decline"} />
            </Popover>
          </Space>
        ) : (
          <span>This is already executed.</span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "",
      key: "date",
      width: 120,
      render: ({ toDate, fromDate }) => (
        <span>
          {`${fromDate}`}
          <br />
          To
          <br />
          {`${toDate}`}
        </span>
      ),
    },
    {
      title: "Resource",
      dataIndex: "resourceName",
      key: "resourceName",
      width: 120,
    },
    {
      title: "Project Id",
      dataIndex: "projectId",
      key: "projectId",
      width: 120,
    },
    {
      title: "Billing",
      dataIndex: "monthlyBillingInfo",
      key: "monthlyBillingInfo",
      width: 120,
      render: (monthlyBillingInfo) => (
        <span className="mv-4">{`${monthlyBillingInfo?.$numberDecimal}`}</span>
      ),
    },
    {
      title: "Metrics",
      dataIndex: "",
      key: "",
      width: 150,
      render: ({ ccoeMetricType }) => (
        <span className="mv-4">{`${ccoeMetricType.displayName}`}</span>
      ),
    },
    {
      title: "Statistical Analysis",
      dataIndex: "",
      key: "metricGenerator",
      width: 250,
      render: ({ mean, median, mode, standardDeviation, variance }, index) => (
        <span className="mv-4">
          <Tag className="mv-2">
            <b>Mean</b>:{afterDecimalTwoDigit(mean)}
          </Tag>
          <Tag className="mv-2">
            <b>Median</b>:{afterDecimalTwoDigit(median)}
          </Tag>
          <Tag className="mv-2">
            <b>mode</b>:{afterDecimalTwoDigit(mode)}
          </Tag>{" "}
          <br />
          <Tag className="mv-2">
            <b>Standard Deviation</b>:{afterDecimalTwoDigit(standardDeviation)}
          </Tag>
          <Tag className="mv-2">
            <b>Variance</b>:{afterDecimalTwoDigit(variance)}
          </Tag>
        </span>
      ),
    },
    {
      title: "Details",
      dataIndex: "",
      key: "recommendationMapId",
      width: 250,
      render: ({ ccoeMetricType }) => (
        <span className="mv-4">
          <Tag className="mv-2">
            <b>Action</b>:{` ${ccoeMetricType.ccoeAction}`}
          </Tag>
          <br />
          <Tag className="mv-2">
            <b>CCOE Metric Type</b>:{`${ccoeMetricType.displayName}`}
          </Tag>
          <br />
          <Tag className="mv-2">
            <b>Resource Type</b>:{` ${ccoeMetricType?.monitoredResource}`}
          </Tag>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getAllRecommendationGeneratorDetails();
  }, []);

  const onFormFinish = ({ recommendation_Id, month, projectId }) => {
    if (!recommendation_Id && !projectId && !month) return;

    setSearchData({});
    const data = {
      query: "active",
      month: month ? moment(month).format("YYYY-MM").toUpperCase() : null,
      recommendation_Id: recommendation_Id,
      projectId: projectId,
    };

    for (const property in data) {
      if (!data[property]) {
        delete data[property];
      }
    }

    dispatch(getRecommendationGeneratorFilter(data));
    setSearchData(data);
    setIsFilter(true);
    // form.resetFields();
  };

  const getAllRecommendationGeneratorDetails = () => {
    setIsFilter(false);
    setSearchData({});
    form.resetFields();
    dispatch(
      getAllRecommendationGenerator({
        page: 1,
        limit,
        query: "active",
      })
    );
  };

  const onPageChange = (pageNum) =>
    isFilter
      ? getRecommendationGeneratorFilter({ page: pageNum, ...searchData, query: "active" })
      : dispatch(getAllRecommendationGenerator({ page: pageNum, query: "active" }));

  return (
    <Layout className="bg-transparent">
      <Row span={24} xs={24} sm={24} lg={24} className="flex ">
        <Col span={12} xs={12} sm={12} lg={12} className="flex justify-start items-center">
          <RiseOutlined style={{ fontSize: "24px" }} />
          <span className="text-2xl ml-2"> Recommendations</span>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={onFormFinish}>
        <Row className="fields-row" gutter={20} type="flex" justify="space-between">
          <FilterField inputsData={filterFields} span={8} xs={8} sm={8} lg={8} />
          <Col span={6} xs={6} sm={6} lg={6} className="flex ">
            {/* <DatePicker name="month" label="Select Month" type="month" /> */}
          </Col>

          <Col span={24} xs={24} sm={24} lg={24} className="flex justify-end">
            <Space>
              <Button
                type="primary"
                disabled={!isFilter}
                onClick={getAllRecommendationGeneratorDetails}
                text={"Reset"}
                icon={<RedoOutlined />}
              />
              <Button
                type="primary"
                additionalProps={{
                  htmlType: "submit",
                }}
                text={"Filter"}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
      <Layout className="mt-4 bg-transparent">
        <Table
          columns={columns}
          dataSource={recommendationGeneratorData}
          pagination={{ total: count, onChange: (e) => onPageChange(e) }}
          isLoading={isLoading}
          rowKey="_id"
        />
      </Layout>
    </Layout>
  );
};

export default RecommendationGenerator;
