import { configureStore } from "@reduxjs/toolkit";
import {
  UserReducer,
  AuthReducer,
  TeamReducer,
  CloudAccountReducer,
  ResourceReducer,
  RecommendationReducer,
  MetricsReducer,
  MetricsAggregatorReducer,
  RecommendationGeneratorReducer,
  RecommendationExecutorReducer,
  MetricTypesReducer,
  ReportsReducer,
  ProjectsReducer
} from "redux/reducers";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    user: UserReducer,
    team: TeamReducer,
    cloudAccount: CloudAccountReducer,
    resource: ResourceReducer,
    recommendation: RecommendationReducer,
    metrics: MetricsReducer,
    metricAggregator: MetricsAggregatorReducer,
    recommendationGenerator: RecommendationGeneratorReducer,
    recommendationExecutor: RecommendationExecutorReducer,
    metricTypes: MetricTypesReducer,
    reports: ReportsReducer,
    projects: ProjectsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
