import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "constants";
import { useFetcher } from "hooks";

const getAllTeams = createAsyncThunk("teams/all", async (_, { rejectWithValue }) => {
  const options = {
    url: API_URL.ALL_TEAMS,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});
const getTeamFilters = createAsyncThunk("team/filter", async (params, { rejectWithValue }) => {
  const { page = 1 } = params;
  const options = {
    url: `${API_URL.ALL_TEAMS}?page=${page}`,
    method: "POST",
    isAuth: true,
    data: {
      ...params,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getTeamsCount = createAsyncThunk("team/getTeamsCount", async (_, { rejectWithValue }) => {
  const options = {
    url: `${API_URL.ALL_TEAMS}/count`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const createTeam = createAsyncThunk("team/create", async (params, { rejectWithValue }) => {
  const { fieldsValue } = params;
  const options = {
    url: API_URL.TEAM,
    method: "POST",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const deleteTeam = createAsyncThunk("team/delete", async (params, { rejectWithValue }) => {
  const { id } = params;
  const options = {
    url: `${API_URL.TEAM}/${id}`,
    method: "DELETE",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const updateTeam = createAsyncThunk("team/udpate", async (params, { rejectWithValue }) => {
  const { fieldsValue, id } = params;
  const options = {
    url: `${API_URL.TEAM}/${id}`,
    method: "PUT",
    isAuth: true,
    data: {
      ...fieldsValue,
    },
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

const getTeam = createAsyncThunk("team/getTeam", async (params, { rejectWithValue }) => {
  const { id } = params;
  const options = {
    url: `${API_URL.TEAM}/${id}`,
    method: "GET",
    isAuth: true,
  };
  try {
    const data = await useFetcher(options);
    return data;
  } catch (err) {
    return rejectWithValue(err?.response?.data);
  }
});

export { getAllTeams, getTeamsCount, createTeam, deleteTeam, updateTeam, getTeam, getTeamFilters };
