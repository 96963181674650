import { Table, Layout } from "components";
// TODO: needs to adjust in to the fixtures files

const ViewAllRecommendationActions = ({ recommendationActions }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "id",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <Layout className="bg-transparent">
      <Layout className="mt-4 bg-transparent">
        <Table columns={columns} dataSource={recommendationActions} rowKey="_id" />
      </Layout>
    </Layout>
  );
};

export default ViewAllRecommendationActions;
