import axios from "axios";
import { useLocalStorage } from "hooks";

const useFetcher = async (opts) => {
  const { getItem } = useLocalStorage();
  const accessToken = getItem("access_token");
  opts = {
    ...opts,
    ...(opts.isAuth && {
      headers: {
        Authorization: `${accessToken}`,
      },
    }),
  };
  try {
    const response = await axios(opts);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default useFetcher;
