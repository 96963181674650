/* eslint-disable no-template-curly-in-string */
const UI_VALIDATION = {
  required: "${label} is required!",
  types: {
    email: "Enter a valid email!",
    number: "${label} is not a valid number!",
  },
  organization: {
    email: "Invalid organization email.",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
  string: {
    max: "cannot be longer then ${max} characters",
  },
  noLabelRequire: "This field is required",
  invalidPassword: "Minimum 8 characters long and At least one upper case letter",
  invalidCellNumber: "Please enter a valid Cell# with country code",
};

export default UI_VALIDATION;
