const API_URL = {
  LOGIN: process.env.REACT_APP_LOGIN_URL,
  FORGOT_PASSWORD: process.env.REACT_APP_FORGOT_PASSWORD,
  RESET_PASSWORD: process.env.REACT_APP_RESET_PASSWORD,
  CHANGE_PASSWORD: process.env.REACT_APP_CHANGE_PASSWORD,
  USERS_ALL: process.env.REACT_APP_USERS_ALL,
  CREATE_USER: process.env.REACT_APP_CREATE_USER,
  USER_PROFILE: process.env.REACT_APP_USER_PROFILE,
  ALL_TEAMS: process.env.REACT_APP_TEAMS,
  TEAM: process.env.REACT_APP_TEAM,
  ALL_CLOUD_ACCOUNTS: process.env.REACT_APP_CLOUD_ACCOUNTS,
  CLOUD_ACCOUNT: process.env.REACT_APP_CLOUD_ACCOUNT,
  ALL_RESOURCES: process.env.REACT_APP_RESOURCES,
  RESOURCE: process.env.REACT_APP_RESOURCE,
  ALL_RECOMMENDATIONS: process.env.REACT_APP_RECOMMENDATIONS,
  RECOMMENDATION: process.env.REACT_APP_RECOMMENDATION,
  RECOMMENDATION_ACTIVITY: process.env.REACT_APP_RECOMMENDATION_ACTIVITY,
  METRICS: process.env.REACT_APP_METRICS,
  METRICS_AGGREGATORS: process.env.REACT_APP_METRICS_AGGREGATORS,
  RECOMMENDDATION_GENERATORS: process.env.REACT_APP_RECOMMENDDATION_GENERATORS,
  RECOMMENDATION_EXECUTOR: process.env.REACT_APP_RECOMMENDATION_EXECUTOR,
  ALL_METRIC_TYPES: process.env.REACT_APP_METRIC_TYPES,
  REPORTS: process.env.REACT_APP_REPORTS,
  ALL_PROJECTS: process.env.REACT_APP_PROJECTS,
};

export { API_URL };
