import { DatePicker as AntDatePicker, Form } from "antd";
import noop from "lodash/noop";
import { PropTypes } from "prop-types";


const RangePicker = ({
  onPanelChange,
  onChange,
  text,
  type,
  isLoading,
  icon,
  validationRules,
  name,
  label,
  disabled,
  additionalProps,
}) => (
  <Form.Item name={name} rules={validationRules} label={label}>
    <AntDatePicker.RangePicker
      data-testid="atom-rangePicker"
      picker={type}
      onPanelChange={onPanelChange}
      onChange={onChange}
      loading={isLoading}
      // icon={icon}
      disabled={disabled}
      {...additionalProps}
    >
      {text}
    </AntDatePicker.RangePicker>
  </Form.Item>
);
RangePicker.defaultProps = {
  onPanelChange: noop,
  onChange: noop,
  text: "no text",
  type: "primary",
  isLoading: false,
  disabled: false,
  additionalProps: {},
};
RangePicker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalProps: PropTypes.shape({}),
};
export default RangePicker;
